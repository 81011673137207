import React, { useState, useEffect } from "react";
import {
    Modal,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Box,
    Checkbox,
    ListItemText,
    CircularProgress,
    Chip,
} from "@mui/material";
import '../../../App.css';
import {
    neutral800,
    primary500,
    primary600,
    primary700,
    white,
    neutral700,
    neutral500,
    primary580,
    black,
    grey600,
    neutral400,
    elevationLight1,
    neutral300,
} from "../../../lib/rebrandColors/colors";
import Dropdown from "../../dropDown";
import TextField from "../../textField";
import CustomButton from "../../customButton";
import IconButton from "../../iconButton";
import styles from '../styles';
import { handleCreateSchedule, handleEditSubmit, handleScheduleDeleteSubmit } from "../../../functions/scheduleTable";
import CustomIcon from "../../icon"
import { calculateProjectedSavings } from "../../../functions/savings"

const minutes_arr = Array.from({ length: 61 }, (_, i) => String(i).padStart(2, '0'));
const hours_arr = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));

export default function ScheduleForm({
    edit,
    open,
    handleClose,
    outlets,
    child_org_data,
    schedules,
    schedule = null,
    setClickedSchedule,
    selectedOrgID,
    orgID,
    setSchedules,
    refresh,
    setRefresh
}) {
    const [selectedSchedule, setSelectedSchedule] = useState(schedule);
    const [projectedSavings, setProjectedSavings] = useState(0); // State to store projected savings
    const currentSchedule = schedule;
    const [missionId, setMissionId] = useState('');
    const [onHour, setOnHour] = useState('12');
    const [onMinute, setOnMinute] = useState('00');
    const [offHour, setOffHour] = useState('12');
    const [offMinute, setOffMinute] = useState('00');
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [repeat, setRepeat] = useState([]);
    const [onMinDropdownOpen, setOnMinDropdownOpen] = useState(false);
    const onMinDropdownClick = (item) => {
        setOnMinute(item);
    };
    const [onHourDropdownOpen, setOnHourDropdownOpen] = useState(false);
    const onHourDropdownClick = (item) => {
        setOnHour(item);
    };
    const [offMinDropdownOpen, setOffMinDropdownOpen] = useState(false);
    const offMinDropdownClick = (item) => {
        setOffMinute(item);
    };
    const [offHourDropdownOpen, setOffHourDropdownOpen] = useState(false);
    const offHourDropdownClick = (item) => {
        setOffHour(item);
    };

    const [offAMPMDropdownValue, setOffAMPMDropdownValue] = useState('PM');
    const [offAMPMDropdownOpen, setOffAMPMDropdownOpen] = useState(false);
    const offAMPMDropdownClick = (item) => {
        setOffAMPMDropdownValue(item);
    };

    const AMPMDropdownArr = ['AM', 'PM'];

    const [onAMPMDropdownValue, setOnAMPMDropdownValue] = useState('AM');
    const [onAMPMDropdownOpen, setOnAMPMDropdownOpen] = useState(false);
    const onAMPMDropdownClick = (item) => {
        setOnAMPMDropdownValue(item);
    };

    const weekArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const toggleDay = (day) => {
        setRepeat((prevRepeat) =>
            prevRepeat.includes(day)
                ? prevRepeat.filter((d) => d !== day) // Remove day if it exists
                : [...prevRepeat, day] // Add day if it doesn't exist
        );
    };

    const [productTypesArr, setProductTypesArr] = useState([]);
    // product types for displaying existing schedules to edit 
    useEffect(() => {
        const newProductTypesArr = schedules.map((schedule) => {
            const productTypes = schedule.outletsSelected
                .map(outlet => outlets.find(obj => obj.id === outlet)?.productType)
                .filter(Boolean);
            return [...new Set(productTypes)];
        });
        setProductTypesArr(newProductTypesArr);
    }, [schedules, outlets]);

    //if editing a schedule set all the schedule values
    useEffect(() => {
        if (edit) {
            if (schedule !== null) {
                setSelectedSchedule(schedule);
            };
            if (selectedSchedule !== null && selectedSchedule !== undefined) {
                if ('missionId' in selectedSchedule) {
                    setMissionId(
                        selectedSchedule.missionId !== '' ?
                            selectedSchedule.missionId :
                            child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
                    );
                };
                if ('onHour' in selectedSchedule) {
                    if (selectedSchedule.onHour == 0) {
                        setOnHour('12');
                    } else {
                        setOnHour(selectedSchedule.onHour > 12 ? selectedSchedule.onHour - 12 : selectedSchedule.onHour);
                    };
                    setOnMinute(selectedSchedule.onMinute.toString().length !== 2 ?
                        `0${selectedSchedule.onMinute}` : selectedSchedule.onMinute);
                    setOffHour(selectedSchedule.offHour > 12 ? selectedSchedule.offHour - 12 : selectedSchedule.offHour);
                    setOffMinute(selectedSchedule.offMinute.toString().length !== 2 ?
                        `0${selectedSchedule.offMinute}` : selectedSchedule.offMinute);
                    setOffAMPMDropdownValue(selectedSchedule.offHour >= 12 ? 'PM' : 'AM');
                    setOnAMPMDropdownValue(selectedSchedule.onHour >= 12 ? 'PM' : 'AM');
                    setSelectedOutlets(outlets.filter((obj) => selectedSchedule.outletsSelected.includes(obj.id)));
                };
                if ('repeat' in selectedSchedule) {
                    setRepeat(selectedSchedule.repeat);
                };
            };
        };
    }, [selectedSchedule, schedule]);

    // Fetch projected savings when a schedule is selected or updated
    useEffect(() => {
        const fetchProjectedSavings = async () => {
            if (selectedOutlets.length > 0) {
                let onHourHelp = Number(onHour)
                if (onAMPMDropdownValue == 'PM') {
                    onHourHelp = onHourHelp + 12
                } else if (onHourHelp == 12) {
                    onHourHelp = 0;
                };
                let offHourHelp = Number(offHour)
                if (offAMPMDropdownValue == 'PM' && offHourHelp != 12) {
                    offHourHelp = offHourHelp + 12
                } else if (offAMPMDropdownValue == 'AM' && offHourHelp == 12) {
                    offHourHelp = 0;
                };

                // Use Promise.all to fetch savings concurrently
                const savingsArray = await Promise.all(
                    selectedOutlets.map((outlet) => {
                        const savings = calculateProjectedSavings(
                            outlet.id, // Outlet ID
                            onHourHelp,
                            onMinute,
                            offHourHelp,
                            offMinute,
                            repeat
                        );
                        //console.log("Savings for outlet", outlet.id, ":", savings);
                        return savings;
                    })
                );

                // Calculate total savings
                const totalSavings = savingsArray.reduce((sum, savings) => sum + savings, 0);
                setProjectedSavings(totalSavings);
            };
        };

        fetchProjectedSavings();
    }, [selectedOutlets, repeat, onHour, offHour, onAMPMDropdownValue, offAMPMDropdownValue]);

    const onSubmit = () => {
        let newOnHour = Number(onHour);
        if (newOnHour === 12 && onAMPMDropdownValue === 'AM') {
            newOnHour = 0;
        } else if (newOnHour < 12 && onAMPMDropdownValue === 'PM') {
            newOnHour += 12;
        };
        const newOnMinute = Number(onMinute);

        let newOffHour = Number(offHour);
        if (newOffHour === 12 && offAMPMDropdownValue === 'AM') {
            newOffHour = 0;
        } else if (newOffHour < 12 && offAMPMDropdownValue === 'PM') {
            newOffHour += 12;
        };
        const newOffMinute = Number(offMinute);

        const newRepeat = repeat.sort((a, b) => weekArr.indexOf(a) - weekArr.indexOf(b));

        // Transforming the array to just contain the outlet ids
        const newOutletsSelected = selectedOutlets.map(obj => obj.id);
        let eventData = {};
        if (schedule !== null) {
            eventData = {
                id: selectedSchedule.id,
                missionId,
                offHour: newOffHour,
                offMinute: newOffMinute,
                onHour: newOnHour,
                onMinute: newOnMinute,
                outletsSelected: newOutletsSelected,
                repeat: newRepeat,
            };
            handleEditSubmit(eventData, refresh, setRefresh)
        } else {
            eventData = {
                missionId,
                offHour: newOffHour,
                offMinute: newOffMinute,
                onHour: newOnHour,
                onMinute: newOnMinute,
                outletsSelected: newOutletsSelected,
                repeat: newRepeat,
            };
            handleCreateSchedule(eventData, selectedOrgID, orgID, setSchedules);
        };
        setSelectedSchedule(null);
        setClickedSchedule(null);
        handleClose();
    };

    const onDeleteSubmit = () => {
        handleScheduleDeleteSubmit(selectedSchedule.id, refresh, setRefresh);
        handleClickClose();
    };

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const handleDeleteClick = () => {
        setShowConfirmPopup(true);
    };

    const handleConfirmDelete = () => {
        setShowConfirmPopup(false);
        onDeleteSubmit(); // Call the delete function
    };

    const handleCancelDelete = () => {
        setShowConfirmPopup(false);
    };

    const handleClickClose = () => {
        setMissionId('');
        setOnHour('12');
        setOnMinute('00');
        setOffHour('12');
        setOffMinute('00');
        setSelectedOutlets([]);
        setRepeat([]);
        setClickedSchedule();
        setSelectedSchedule();
        setOnHourDropdownOpen(false);
        setOffHourDropdownOpen(false);
        setOnMinDropdownOpen(false);
        setOffMinDropdownOpen(false);
        setProjectedSavings(0);
        setOffAMPMDropdownValue('PM');
        setOnAMPMDropdownValue('AM');
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClickClose}>
            <Box sx={styles.container} >
                <div style={styles.innerContainer}>
                    <div style={styles.headerContainer}>
                        <div
                            className="titleLarge"
                            style={styles.headerText}
                        >
                            {!edit ? 'Create a New Schedule' : selectedSchedule === null ? 'Select a Schedule to Edit' : 'Edit Schedule'}
                        </div>
                        <IconButton
                            iconName='cancel-1'
                            tone='dark'
                            size={24}
                            onClick={handleClickClose}
                        />
                    </div>
                    {/*List of Schedules to Edit if Editing a Schedule
                        TODO: Chnage this to a component
                        List of schedules not used at the moment
                    */}
                    {/* {selectedSchedule === null && edit &&
                        (schedules.length > 0 ?
                            schedules.map((schedule, i) => {
                                return (
                                    <div>
                                        <div
                                            key={schedule.id}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '500px',
                                                height: '68px',
                                                alignItems: 'center',
                                                boxShadow: elevationLight1,
                                                borderRadius: '16px',
                                                border: `1px solid ${neutral300}`,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setSelectedSchedule(schedule)}
                                        >
                                            <div style={{
                                                height: '48px',
                                                width: '58px',
                                                borderRadius: '50px',
                                                backgroundColor: '#F1F1F1',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginLeft: '5px'
                                            }}>
                                                <CustomIcon
                                                    name="building-1"
                                                    color={black}
                                                    size={24}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                marginLeft: '10px'
                                            }}>
                                                <div
                                                    className="labelMedium"
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '700',
                                                        color: grey600
                                                    }}
                                                >
                                                    {schedule.missionId !== '' ?
                                                        schedule.missionId
                                                        : child_org_data.find(org => org.uid === schedule.orgID)?.name
                                                    }
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'start',
                                                    width: '100%',
                                                    marginTop: '3px'
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        width: '97%',
                                                        overflow: 'hidden',
                                                    }}>
                                                        {productTypesArr[i]?.length > 0 ?
                                                            productTypesArr[i].map((prodType, j) => {
                                                                return (
                                                                    <div
                                                                        className="labelLarge"
                                                                        key={`${productTypesArr[i]}${j}`}
                                                                        style={{
                                                                            fontSize: productTypesArr[i].length > 2 ? '10px' : '12px',
                                                                            marginRight: '2%',
                                                                            color: neutral400,
                                                                            lineHeight: '10px',
                                                                        }}
                                                                    >
                                                                        {prodType}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '50px'
                                                            }}>
                                                                <CircularProgress />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div
                                                        className="labelSmall"
                                                        style={{
                                                            color: neutral400,
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div style={{
                                                                paddingRight: '2px',
                                                            }}>
                                                                Off:
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.offHour > 12 ?
                                                                        schedule.offHour - 12 :
                                                                        schedule.offHour === 0 ?
                                                                            12 : schedule.offHour
                                                                }
                                                            </div>
                                                            <div>
                                                                :
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.offMinute.toString().length !== 2 ?
                                                                        `0${schedule.offMinute}` :
                                                                        schedule.offMinute
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            marginLeft: '5px',
                                                        }}>
                                                            <div style={{
                                                                paddingRight: '2px',
                                                            }}>
                                                                On:
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.onHour > 12 ?
                                                                        schedule.onHour - 12 :
                                                                        schedule.onHour === 0 ?
                                                                            12 : schedule.onHour
                                                                }
                                                            </div>
                                                            <div>
                                                                :
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.onMinute.toString().length !== 2 ?
                                                                        `0${schedule.onMinute}` :
                                                                        schedule.onMinute
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '50px'
                                }}
                                className="headlineSmall"
                            >
                                No schedules today
                            </div>
                        )
                    } */}
                    <TextField
                        label="Schedule Name"
                        placeholder={
                            selectedSchedule == null ?
                                "Enter schedule name"
                                :
                                selectedSchedule.missionId !== '' ?
                                    selectedSchedule.missionId :
                                    child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
                        }
                        required
                        value={missionId}
                        onChange={setMissionId}
                        size="medium"
                        background='light'
                    />
                    <div styles={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: black,
                        marginBottom: '20px',
                    }}>
                        Projected Weekly Savings: ${projectedSavings.toFixed(2)}
                    </div>
                    {/* On Block */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        <Dropdown
                            list={hours_arr}
                            value={onHour}
                            onSelect={onHourDropdownClick}
                            setIsDropdownOpen={setOnHourDropdownOpen}
                            isDropdownOpen={onHourDropdownOpen}
                            label={'On Hour'}
                            size={100}
                            background="light"
                        />
                        <div style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                        }}>
                            :
                        </div>
                        <Dropdown
                            list={minutes_arr}
                            value={onMinute}
                            onSelect={onMinDropdownClick}
                            setIsDropdownOpen={setOnMinDropdownOpen}
                            isDropdownOpen={onMinDropdownOpen}
                            label={'On Minute'}
                            size={100}
                            background="light"
                        />
                        <div style={{ marginLeft: '15px', width: 100}}>
                            <Dropdown
                                list={AMPMDropdownArr}
                                value={onAMPMDropdownValue}
                                onSelect={onAMPMDropdownClick}
                                setIsDropdownOpen={setOnAMPMDropdownOpen}
                                isDropdownOpen={onAMPMDropdownOpen}
                                label={'AM/PM'}
                                size={75}
                                background="light"
                            />
                        </div>
                    </div>
                    {/* Off Block */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Dropdown
                            list={hours_arr}
                            value={offHour}
                            onSelect={offHourDropdownClick}
                            setIsDropdownOpen={setOffHourDropdownOpen}
                            isDropdownOpen={offHourDropdownOpen}
                            label={'Off Hour'}
                            size={100}
                            background="light"
                        />
                        <div style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                        }}>
                            :
                        </div>
                        <Dropdown
                            list={minutes_arr}
                            value={offMinute}
                            onSelect={offMinDropdownClick}
                            setIsDropdownOpen={setOffMinDropdownOpen}
                            isDropdownOpen={offMinDropdownOpen}
                            label={'Off Minute'}
                            size={100}
                            background="light"
                        />
                        <div style={{ marginLeft: '15px', width: 100 }}>
                            <Dropdown
                                list={AMPMDropdownArr}
                                value={offAMPMDropdownValue}
                                onSelect={offAMPMDropdownClick}
                                setIsDropdownOpen={setOffAMPMDropdownOpen}
                                isDropdownOpen={offAMPMDropdownOpen}
                                label={'AM/PM'}
                                size={75}
                                background="light"
                            />
                        </div>
                    </div>
                    {/* Outlets */}
                    <FormControl sx={{ width: "485px", marginTop: '10px' }}>
                        <Select
                            //labelId="outlet-label"
                            multiple
                            value={selectedOutlets}
                            onChange={(e) => setSelectedOutlets(e.target.value)}
                            //label="Outlets"
                            required
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selected.map((outlet) => (
                                        <Chip key={outlet.id} label={outlet.name} sx={{ margin: '2px' }} />
                                    ))}
                                </Box>
                            )}
                        //selected.length ? selected.join(", ") : "Select Outlets"
                        //}
                        >
                            {outlets.map((outlet) => (
                                <MenuItem key={outlet.id} value={outlet}>
                                    {/* {outlet.name} */}
                                    <Checkbox checked={selectedOutlets.includes(outlet)} />
                                    <ListItemText primary={outlet.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Select outlets to schedule</FormHelperText>
                    </FormControl>
                    {/* Repeat */}
                    <div style={{
                        marginTop: '5px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div
                            className="labelMedium"
                            style={{ color: neutral800 }}
                        >
                            Days:
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginTop: '10px',
                        }}>
                            {weekArr.map(day => (
                                <div
                                    key={day}
                                    onClick={() => toggleDay(day)}
                                    style={{
                                        borderWidth: '1px',
                                        borderColor: repeat.includes(day) ? primary500 : neutral800,
                                        borderStyle: 'solid',
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                        marginLeft: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        color: repeat.includes(day) ? white : neutral800,
                                        backgroundColor: repeat.includes(day) ? primary500 : white,
                                    }}
                                    className="buttonMedium"
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                        <CustomButton
                            filled={true}
                            label={schedule?.status === 'New' ? "Create Schedule" : "Update Schedule"}
                            onClick={onSubmit}
                            size="medium"
                            disabled={
                                (
                                    missionId !== '' &&
                                    (onHour !== offHour || onMinute !== offMinute || onAMPMDropdownValue !== offAMPMDropdownValue) &&
                                    selectedOutlets.length !== 0 &&
                                    repeat.length !== 0
                                ) ?
                                    false :
                                    true
                            }
                        />
                    </div>
                    {edit && (
                        <>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                                <CustomButton
                                    filled={false}
                                    label="Delete Schedule"
                                    onClick={handleDeleteClick}
                                    size="medium"
                                />
                            </div>
                            {showConfirmPopup && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '20px',
                                            borderRadius: '10px',
                                            boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                                            textAlign: 'center',
                                            width: '400px'
                                        }}
                                    >
                                        <div
                                            className="labelLarge"
                                        >
                                            Are you sure you want to delete this schedule?
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            marginTop: '20px'
                                        }}>
                                            <CustomButton
                                                filled={true}
                                                label="Yes, Delete"
                                                onClick={handleConfirmDelete}
                                                size="small"
                                            />
                                            <CustomButton
                                                filled={false}
                                                label="Cancel"
                                                onClick={handleCancelDelete}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
};