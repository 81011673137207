import React, { useState, useEffect } from "react";
import {
  primary500,
  grey600,
} from "../../lib/rebrandColors/colors";
import { useLocation, useNavigate } from "react-router-dom";
import "../../App.css"; // Import your fonts
import TextButton from "../textButton";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import Chip from "../chip";
import ApplianceTypeList from "../applianceTypeList";

export default function OutletList({
  outlets,
  setOutlets,
  width,
  height,
  wattHours,
  savings = [],
  orgData,
  loading,
  prodTypes,
  modalEdit,
  setModalEdit,
  averageUsage,
  role,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState(outlets);
  const [currentOutlet, setOutlet] = useState({});
  const [index, setIndex] = useState();
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const path = location.pathname;
  const [selectedTab, setSelectedTab] = useState(location.state?.updateTab ? 1 : 0);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setTimezone(localStorage.getItem("timezone"));
  }, [orgData]);

  const handleRowClick = (outlet, i) => {
    setIndex(i);
    setOutlet(outlet);
    navigate("/appliances/details", {
      state: {
        outlet,
        prodTypes,
        orgData,
        wattHours,
        averageUsage,
        savings,
        path,
      },
    });
  };

  const filterOutlets = (outlets, filterType) => {
    return outlets.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      const wifiStatusA = a.wifiStatus;
      const wifiStatusB = b.wifiStatus;
      const usageA = a.dailyUsage;
      const usageB = b.dailyUsage;
      const statusA = a.status;
      const statusB = b.status;
      const savingsA = a.costSavings;
      const savingsB = b.costSavings;
      if (filterType === "name") {
        if (filter) {
          if (nameA < nameB) {
            return -1;
          };
          if (nameA > nameB) {
            return 1;
          };
        } else {
          if (nameA < nameB) {
            return 1;
          };
          if (nameA > nameB) {
            return -1;
          };
        };
      } else if (filterType === "wifiStatus") {
        if (filter) {
          if (wifiStatusA < wifiStatusB) {
            return -1;
          };
          if (wifiStatusA > wifiStatusB) {
            return 1;
          };
        } else {
          if (wifiStatusA < wifiStatusB) {
            return 1;
          };
          if (wifiStatusA > wifiStatusB) {
            return -1;
          };
        };
      } else if (filterType === "usage") {
        if (filter) {
          if (usageA < usageB) {
            return 1;
          };
          if (usageA > usageB) {
            return -1;
          };
        } else {
          if (usageA < usageB) {
            return -1;
          };
          if (usageA > usageB) {
            return 1;
          };
        };
      } else if (filterType === "status") {
        if (filter) {
          if (statusA < statusB) {
            return -1;
          };
          if (statusA > statusB) {
            return 1;
          };
        } else {
          if (statusA < statusB) {
            return 1;
          };
          if (statusA > statusB) {
            return -1;
          };
        };
      } else if (filterType === "savings") {
        if (filter) {
          if (savingsA < savingsB) {
            return 1;
          };
          if (savingsA > savingsB) {
            return -1;
          };
        } else {
          if (savingsA < savingsB) {
            return -1;
          };
          if (savingsA > savingsB) {
            return 1;
          };
        };
      };
      return 0;
    });
  };

  const updateArray2 = (array1, array2) => {
    const updatedArray2 = array2.map((item2) => {
      const match = array1.find((item1) => {
        return item1?.devices?.includes(item2.device);
      });
      if (match) {
        return { ...item2, orgName: match.name };
      };
      return item2;
    });
    return updatedArray2;
  };

  useEffect(() => {
    const adjustOutlets = async () => {
      setFilterLoading(true);
      let today = new Date().toLocaleDateString("en-US", {
        timeZone: timezone || "UTC",
      });
      const [month, day, year] = today.split("/");
      today = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      const filteredWattHours = wattHours.filter((obj) => obj.date === today);
      const sumWattHours = filteredWattHours.map((obj) => ({
        ...obj,
        dailyUsage: obj.hourlyWattHour.reduce(
          (acc, val) => (val > 0 ? acc + val : acc),
          0
        ),
      }));
      const idToDailyUsageMap = new Map(
        sumWattHours.map((obj) => [obj.outletID, obj.dailyUsage])
      );

      const sumValuesById = (array) => {
        let result = [];
        if (array.length > 0) {
          result = array.reduce((acc, { outletID, costSavings }) => {
            if (acc[outletID]) {
              acc[outletID].costSavings += costSavings;
            } else {
              acc[outletID] = { outletID, costSavings };
            };
            return acc;
          }, {});
        };
        return Object.values(result);
      };

      const sumSavings = sumValuesById(savings);
      const idToSavingsMap = new Map(
        sumSavings.map((obj) => [obj.outletID, obj.costSavings])
      );

      if (outlets) {
        if (outlets.length > 0) {
          for (const obj of outlets) {
            if (idToDailyUsageMap.has(obj.id)) {
              obj.dailyUsage = idToDailyUsageMap.get(obj.id) / 1000;
            };
            if (!obj.dailyUsage) {
              obj.dailyUsage = 0;
            };
            if (idToSavingsMap.has(obj.id)) {
              obj.costSavings = idToSavingsMap.get(obj.id);
            };
            if (!obj.costSavings) {
              obj.costSavings = 0;
            };
          };
          outlets = updateArray2(orgData, outlets);
          setFilteredOutlets(filterOutlets(outlets, "savings"));
        } else {
          setFilteredOutlets([]);
        };
      };
      setFilterLoading(false);
    };
    adjustOutlets();
  }, [loading, orgData, timezone]);
  const displayHeight = height - 25;

  return (
    <div style={{
      margin: ".5%",
      minWidth: "500px",
      borderRadius: "24px",
    }}>
      {/* Tab Bar */}
      <div style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: '20px',
        marginBottom: '20px',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: width ? width : "480px",
        }}>
          <TextButton
            label='Appliance type'
            underlined={true}
            onPress={() => handleTabChange(0)}
            fill={selectedTab == 0 && primary500}
          />
          <div style={{ marginLeft: '10px' }}>
            <TextButton
              label='Individual appliances'
              underlined={true}
              onPress={() => handleTabChange(1)}
              fill={selectedTab == 1 && primary500}
            />
          </div>
        </div>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        height: `${displayHeight}px`,
        overflowY: "scroll",
        alignItems: "center",
        width: "100%",
      }}>
        {filterLoading ? (
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}>
            <CircularProgress />
          </div>
        ) : selectedTab === 1 ? (
          // NOTE: Switch this to flatList
          filteredOutlets.length > 0 ? (
            filteredOutlets.map((outlet, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: width ? width : "480px",
                    minHeight: "88px",
                    backgroundColor: "#EEEEEE",
                    borderRadius: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                    marginTop: i == 0 ? "2px" : "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRowClick(outlet, i)}
                  key={outlet.id}
                >
                  <div style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: grey600,
                        }}
                      >
                        {outlet.name}
                      </div>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: "12px",
                          color: "#666666",
                        }}
                      >
                        {outlet.orgName}
                      </div>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: "12px",
                          color: "#666666",
                        }}
                      >
                        Today: {outlet.dailyUsage.toFixed(2)} kWh
                      </div>
                      <div
                        className="labelMedium"
                        style={{
                          color: outlet.status == "Off" ? primary500 : "green",
                          fontSize: "12px",
                        }}
                      >
                        {outlet.status}
                      </div>
                    </div>
                    {location.pathname === "/appliances" && (
                      <div>
                        <Chip
                          label={
                            outlet.productType == ""
                              ? "N/A"
                              : outlet.productType
                          }
                          alert={false}
                          iconName="plug"
                        />
                      </div>
                    )}
                    {location.pathname === "/appliances" &&
                      (outlet.wifiStatus === "Connected" ? (
                        <div>
                          <Chip
                            label={outlet.wifiStatus}
                            alert={false}
                            icon={<WifiIcon style={{ color: "#009C49" }} />}
                          />
                        </div>
                      ) : outlet.wifiStatus === "Disconnected" ? (
                        <Chip
                          label={outlet.wifiStatus}
                          alert={true}
                          icon={<WifiOffIcon style={{ color: "#FF6D2A" }} />}
                        />
                      ) : (
                        <Chip
                          label={outlet.wifiStatus}
                          alert={true}
                          icon={
                            <WifiLockIcon
                              style={{ marginLeft: "3%", color: "orange" }}
                            />
                          }
                        />
                      ))}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="buttonSmall"
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          textAlign: "center",
                        }}
                      >
                        Estimated
                        <br />
                        savings:
                      </div>
                      <div
                        style={{
                          width: "63px",
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "24px",
                          backgroundColor: "#FFFFFF",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          className="buttonSmall"
                          style={{
                            color: "#E50914",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          ${outlet.costSavings.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="headlineMedium"
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "50px",
                color: "#999999",
              }}
            >
              No appliances available
            </div>
          )
        ) : (
          <ApplianceTypeList outlets={outlets} height={height} savings={savings} width={width} />
        )}
      </div>
    </div>
  );
}