import React, { useState, useEffect } from "react";
import {
  black,
  grey600,
  primary500,
} from "../../lib/rebrandColors/colors";
import { CircularProgress } from "@mui/material";
import CustomIcon from "../icon";
import Chip from "../chip";

export default function ApplianceTypeList({
  outlets,
  savings = [],
  height,
  width,
}) {
  const [filterLoading, setFilterLoading] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState([]);

  useEffect(() => {
    const adjustOutlets = async () => {
      setFilterLoading(true);

      const sumValuesById = (array) => {
        let result = [];
        if (array.length > 0) {
          result = array.reduce((acc, { outletID, costSavings }) => {
            if (acc[outletID]) {
              acc[outletID].costSavings += costSavings;
            } else {
              acc[outletID] = { outletID, costSavings };
            }
            return acc;
          }, {});
        }

        return Object.values(result);
      };

      const sumSavings = sumValuesById(savings);
      const idToSavingsMap = new Map(
        sumSavings.map((obj) => [obj.outletID, obj.costSavings])
      );

      const sumByCategory = outlets.reduce((acc, item) => {
        if (acc[item.productType]) {
          acc[item.productType] += idToSavingsMap.get(item.id) || 0;
        } else {
          acc[item.productType] = idToSavingsMap.get(item.id) || 0;
        }
        return acc;
      }, {});

      let groupedCategories = Object.entries(sumByCategory).map(
        ([productType, totalSavings]) => ({
          productType: productType || "N/A",
          totalSavings,
        })
      );

      groupedCategories = groupedCategories.sort((a, b) => b.totalSavings - a.totalSavings);

      setFilteredOutlets(groupedCategories);
      setFilterLoading(false);
    };

    adjustOutlets();
  }, [outlets, savings]);

  return (
    <div
      style={{
        margin: ".5%",
        minWidth: "500px",
        borderRadius: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: height,
          alignItems: "center",
          width: "100%",
        }}
      >
        {filterLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <CircularProgress />
          </div>
        ) : filteredOutlets.length > 0 ? (
          filteredOutlets.map((category, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                width: width ? width : "480px",
                minHeight: "88px",
                backgroundColor: "#EEEEEE",
                borderRadius: "16px",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "5px",
                marginTop: i == 0 ? "2px" : "16px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="labelMedium"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: grey600,
                    }}
                  >
                    {category.productType}
                  </div>
                </div>
                <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="buttonSmall"
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          textAlign: "center",
                        }}
                      >
                        Estimated
                        <br />
                        savings:
                      </div>
                      <div
                        style={{
                          width: "63px",
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "24px",
                          backgroundColor: "#FFFFFF",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          className="buttonSmall"
                          style={{
                            color: "#E50914",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          ${category.totalSavings.toFixed(2)}
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className="headlineMedium"
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "50px",
              color: "#999999",
            }}
          >
            No appliance types available
          </div>
        )}
      </div>
    </div>
  );
}
