import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { neutral400, primary500 } from "../../lib/rebrandColors/colors";
import TimePicker from "../timePicker";
import WeeklyScheduleViewRecommended from "../weeklyScheduleViewRecommended";
import ScheduleForm from "../forms/scheduleForm";
import { updateDoc, doc } from "firebase/firestore";
import db from "../../firebase";
import { black } from "../../lib/rebrandColors/colors";
import { calculateTotalProjectedSavings } from "../../functions/savings";

export default function CalendarContainerRecommended({
  schedules,
  setSchedules,
  orgID,
  selectedOrgID,
  isOverviewAccount,
  timezone,
  orgName,
  child_orgs,
  orgData,
  loading,
  windowDimensions,
  outlets,
  refresh,
  setRefresh,
  subOrgDropdownValue,
  role,
}) {
  // Filter schedules with status "new"
  const newSchedules = schedules?.filter(
    (schedule) => schedule.status === "New"
  );

  // Default view state
  const [view, setView] = useState("week");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [clickedSchedule, setClickedSchedule] = useState();
  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);
  const scheduleClick = (schedule) => {
    setIsEditModalOpen(true);
    setClickedSchedule(schedule);
    console.log("click", schedule);
  };
  const [projectedSavings, setProjectedSavings] = useState(0);


  // Handle view change
  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  // Update date based on TimePicker selection
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleEditSubmit = async (editData) => {
    console.log("edit schedule result", editData);
    try {
      // NOTE: Update to schedules collection
      const scheduleRef = doc(db, "schedules", editData.id);
      await updateDoc(scheduleRef, {
        missionId: editData.missionId,
        offHour: editData.offHour,
        offMinute: editData.offMinute,
        onHour: editData.onHour,
        onMinute: editData.onMinute,
        outletsSelected: editData.outletsSelected,
        repeat: editData.repeat,
        status: "Accepted",
      });
      console.log("doc updated successfully");
      setRefresh(!refresh);
    } catch (error) {
      console.log("error updating", error);
    }
  };
  // NOTE: ensure that role==='view' cannot accept schedules

  useEffect(() => {
    calculateTotalProjectedSavings(schedules, setProjectedSavings, 'New');
  }, [schedules])

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          color: "#FFFFFF",
          fontFamily: "IBM Plex Mono",
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Recommended Schedules
      </Typography>
      <Box
        sx={{
          width: "1136px",
          height: "792px",
          padding: "24px",
          backgroundColor: "#F9F9F9",
          borderRadius: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: 'space-between',
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* Tabs for View Selection */}
          <div style={{ width: '33%',}}>
            <Tabs
              value={view}
              onChange={handleViewChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: primary500,
                },
                "& .MuiTab-root": {
                  textTransform: "none",
                  minWidth: "auto",
                  padding: "0 16px",
                  color: neutral400,
                  fontFamily: "IBM Plex Mono",
                  fontSize: "14px",
                  fontWeight: 500,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: primary500,
                },
              }}
            >
              <Tab label="Week" value="week" />
            </Tabs>
          </div>

          <div style={{
            fontSize: '18px',
            color: black,
          }}>
            Projected Total Weekly Savings:
            <span style={{ fontWeight: 'bold', }}> ${projectedSavings.toFixed(2)}</span>
          </div>
          {/* used for alignment */}
          <div style={{width: '33%'}}></div>
        </Box>

        {/* Time Picker */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <TimePicker
            timeSpan={view.charAt(0).toUpperCase() + view.slice(1)}
            setTime={handleDateChange}
          />
        </Box>

        {/* Weekly View */}
        {view === "week" &&
          (newSchedules?.length === 0 ? (
            <div
              className="headlineMedium"
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "50px",
                color: "#999999",
              }}
            >
              No schedules today
            </div>
          ) : (
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                overflowY: "auto",
                scrollbarWidth: "none",
              }}
            >
              <WeeklyScheduleViewRecommended
                schedules={newSchedules}
                org={subOrgDropdownValue ? subOrgDropdownValue : "All"}
                outlets={outlets}
                orgData={orgData}
                scheduleClick={scheduleClick}
                setClickedSchedule={setClickedSchedule}
              />
            </Box>
          ))}
        <ScheduleForm
          edit={true}
          open={isEditModalOpen}
          handleClose={closeEditModal}
          outlets={outlets}
          child_org_data={orgData}
          setSchedules={setSchedules}
          orgID={orgID}
          selectedOrgID={selectedOrgID}
          refresh={refresh}
          setRefresh={setRefresh}
          schedule={clickedSchedule}
          setClickedSchedule={setClickedSchedule}
          schedules={schedules}
        />
      </Box>
    </>
  );
}
