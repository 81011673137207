import db from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  arrayUnion,
} from "firebase/firestore";

// Fetch events for a specific organization with optional outletID filter
export const fetchOrgEvents = async (organizationID, setEvents, outletID = null) => {
  try {
    // Create the base query
    let eventsQuery = query(
      collection(db, "events"),
      where("organizationID", "==", organizationID)
    );

    // Add outletID filter if provided
    if (outletID) {
      eventsQuery = query(eventsQuery, where("outletID", "==", outletID));
    }

    const eventsSnapshot = await getDocs(eventsQuery);
    const events = eventsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEvents(events);
  } catch (error) {
    console.error("Error fetching organization events:", error);
  }
};

// Fetch events for child organizations with optional outletID filter
export const fetchChildOrgEvents = async (organizationID, setEvents, child_orgs, outletID = null) => {
  try {
    // Create the base query
    let eventsQuery = query(
      collection(db, "events"),
      where("organizationID", "in", child_orgs)
    );

    // Add outletID filter if provided
    if (outletID) {
      eventsQuery = query(eventsQuery, where("outletID", "==", outletID));
    }

    const eventsSnapshot = await getDocs(eventsQuery);
    const events = eventsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEvents(events);
  } catch (error) {
    console.error("Error fetching child organization events:", error);
  }
};

// Delete a specific event
export const deleteOrgEvent = async (eventID) => {
  try {
    const eventRef = doc(db, "events", eventID);
    if (eventRef) {
      await deleteDoc(eventRef);
      console.log("Event successfully deleted!");
    }
  } catch (error) {
    console.error("Error deleting event: ", error);
  }
};

// Submit handler for special event form
export const handleSpecialEventSubmit = async (eventData, orgData, schedules, refresh, setRefresh) => {
  // gets range of events
  const getDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Move to next day
    }
    return dates;
  };
  const dateRange = getDateRange(eventData.startDate, eventData.endDate);
  // finds orgs for events
  const matchingOrgs = orgData
    .map((org) => {
      const matchedOutlets = eventData.outlets.filter((outletID) => {
        const trimmedOutlet = outletID.slice(0, -2); // Remove the last two characters
        return org.devices.includes(trimmedOutlet);
      });
      return matchedOutlets.length > 0 ? { ...org, matchedOutlets } : null;
    })
    .filter((org) => org !== null);
  // creates event for each day, and each org

  try {
    for (const date of dateRange) {
      const eventDay = date.toLocaleDateString("en-US", { weekday: "short" });

      for (const org of matchingOrgs) {
        const dailyEventData = {
          title: eventData.name,
          date: date.toISOString().split("T")[0],
          organizationID: org.uid,
          outlets: org.matchedOutlets, // Only outlets that match this org
          action: eventData.action,
        };

        // await addDoc(collection(db, "events"), dailyEventData);
        const eventDocRef = await addDoc(
          collection(db, "events"),
          dailyEventData
        );
        const newEventID = eventDocRef.id;

        // updates events array in schedule
        for (const schedule of schedules) {
          if (
            schedule.repeat.includes(eventDay) &&
            org.matchedOutlets.some((outlet) =>
              schedule.outletsSelected.includes(outlet)
            )
          ) {
            // NOTE: Update to schedules collection
            const scheduleDocRef = doc(db, "schedules", schedule.id);

            await updateDoc(scheduleDocRef, {
              events: arrayUnion({
                eventDate: dailyEventData.date,
                outlets: org.matchedOutlets,
                action: dailyEventData.action,
                eventID: newEventID,
              }),
            });
          }
        }
      }
    }
    console.log("Events created successfully!");
    setRefresh(!refresh);
  } catch (error) {
    console.log("doc error", error);
  }
};

// Submit handler for editing a special event
export const handleSpecialEventEditSubmit = async (eventData, orgData, schedules, refresh, setRefresh) => {
  const matchingOrgs = orgData
    .map((org) => {
      const matchedOutlets = eventData.outlets.filter((outlet) => {
        const trimmedOutlet = outlet.id.slice(0, -2); // Remove the last two characters from the outlet id
        return org.devices.includes(trimmedOutlet);
      });
      return matchedOutlets.length > 0 ? { ...org, matchedOutlets } : null;
    })
    .filter((org) => org !== null);
  try {
    const eventDay = new Date(eventData.date).toLocaleDateString("en-US", {
      weekday: "short",
      timeZone: "UTC",
    }); //needs the utc part to confirm the correct day for the repeat
    for (const org of matchingOrgs) {
      const dailyEventData = {
        title: eventData.name,
        date: eventData.date,
        organizationID: org.uid,
        outlets: org.matchedOutlets, // Only outlets that match this org
        action: eventData.action,
        id: eventData.id,
      };

      const eventDocRef = doc(db, "events", eventData.id);
      await updateDoc(eventDocRef, dailyEventData);
      // updates events array in schedule
      for (const schedule of schedules) {
        if (
          schedule.repeat.includes(eventDay) &&
          org.matchedOutlets.some((outlet) =>
            schedule.outletsSelected.includes(outlet)
          )
        ) {
          // NOTE: Update to schedules collection
          const scheduleDocRef = doc(db, "schedules", schedule.id);
          const scheduleDoc = await getDoc(scheduleDocRef);
          if (scheduleDoc.exists()) {
            const scheduleData = scheduleDoc.data();
            const existingEvents = scheduleData.events || [];

            const eventIndex = existingEvents.findIndex(
              (event) => event.eventID === eventData.id
            );

            if (eventIndex !== -1) {
              existingEvents[eventIndex] = {
                eventDate: dailyEventData.date,
                outlets: org.matchedOutlets,
                action: dailyEventData.action,
                eventID: eventData.id,
              };

              await updateDoc(scheduleDocRef, { events: existingEvents });
            }
          }
        }
      }
    }
    console.log("Events Updated successfully!");
    setRefresh(!refresh);
  } catch (error) {
    console.log("doc error", error);
  }
};

// function to handle deleting an event
export const handleSpecialEventDeleteSubmit = async (eventData, schedules, refresh, setRefresh) => {
  try {
    const eventDocRef = doc(db, "events", eventData.id);
    await deleteDoc(eventDocRef);

    // Identify schedules that include this event
    for (const schedule of schedules) {
      const scheduleDocRef = doc(db, "schedules", schedule.id);
      const scheduleDoc = await getDoc(scheduleDocRef);

      if (scheduleDoc.exists()) {
        const scheduleData = scheduleDoc.data();
        const existingEvents = scheduleData.events || [];

        // Filter out the event to be deleted
        const updatedEvents = existingEvents.filter(
          (event) => event.eventID !== eventData.id
        );

        // If the events array was updated, save the changes
        if (updatedEvents.length !== existingEvents.length) {
          await updateDoc(scheduleDocRef, {
            events: updatedEvents,
          });
        }
      }
    }

    console.log(`Event with ID ${eventData.id} deleted successfully!`);
    setRefresh(!refresh);
  } catch (error) {
    console.log("Error deleting event:", error);
  }
};