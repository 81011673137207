import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { fetchUsersFromOrganization } from "../../functions/users";
import {
  fetchOrganizationInfoFromID,
  updateOrganizationRate,
  updateOrganizationBill,
} from "../../functions/organizations";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL, getMetadata } from "firebase/storage";
import CustomButton from "../customButton";
import TextButton from "../textButton";
import IconButton from "../iconButton";
import { useNavigate } from "react-router-dom";
import WifiInformationForm from "../forms/wifiInformationForm";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function OrganizationModal({
  orgID,
  role,
}) {
  const [user, waiting] = useAuthState(auth);
  const [organization, setOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [rate, setRate] = useState("");
  const [billUploaded, setBillUploaded] = useState(false);
  const [allOrgs, setAllOrgs] = useState([]);
  const [file, setFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wifiSubmitted, setWifiSubmitted] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [wifiRecieved, setWifiRecieved] = useState(false);

  useEffect(() => {
    if (!orgID) return;

    const fetchOrganizationAndUsers = async () => {
      let organization = null;

      try {
        organization = await fetchOrganizationInfoFromID(orgID);
        if (organization) {
          console.log('[account info] org: ', organization, organization?.address);

          setOrganization(organization);
          setRate(Number(organization.rate).toFixed(2));
          setBillUploaded(organization.billUpload);
          setWifiRecieved(organization?.wifiInformation ? true : false);
          try {
            setLoading(true);
            const fetchedUsers = await fetchUsersFromOrganization(orgID);
            setUsers(fetchedUsers);
          } catch (error) {
            console.error("Error fetching users:", error);
          } finally {
            setLoading(false);
          };
        };
      } catch (error) {
        console.error("Error fetching organization:", error);
      };
    };

    fetchOrganizationAndUsers();
  }, [orgID, wifiSubmitted]);

  const handleRateChange = (event) => {
    const value = event.target.value;
    // Allow only valid double values
    if (/^\d*\.?\d*$/.test(value)) {
      setRate(value);
    }
  };

  const handleRateSave = async () => {
    if (!organization || !rate) return;
    setRate(Number(rate).toFixed(2))
    try {
      setLoading(true);
      await updateOrganizationRate(organization.uid, parseFloat(rate));
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating rate:", error);
      alert("Failed to update the rate.");
    } finally {
      setLoading(false);
    };
  };
  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return '.pdf';
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
      default:
        return ''; // Return an empty string if the MIME type is unknown
    };
  };
  const downloadFile = async () => {
    try {
      const fileName = `electricityBills/${organization.name}/${organization.name} bill`;
      // Create a reference to the file you want to download
      const fileRef = ref(storage, fileName);

      const url = await getDownloadURL(fileRef);

      const fileMetadata = await getMetadata(fileRef);
      const mimeType = fileMetadata.contentType; // Retrieve the MIME type
      const fileExtension = getFileExtension(mimeType);

      console.log("File URL:", url);

      window.open(url, '_blank');

    } catch (error) {
      console.error("Error downloading file:", error);
    };
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      const fileName = `electricityBills/${organization.name}/${organization.name} bill`;
      const storageRef = ref(storage, fileName);
      setFileLoading(true);
      try {
        await uploadBytes(storageRef, selectedFile);
        console.log('File uploaded successfully');

        await updateOrganizationBill(organization.uid, setBillUploaded);
        console.log('Bill upload field updated successfully');
        setOpenDialog(true);
      } catch (error) {
        console.log('Upload failed', error);
      } finally {
        setFileLoading(false);
      };
    } else {
      alert('Only PDF, JPG, and PNG files are allowed.');
    };
  };

  const infoContainerStyle = {
    marginTop: '16px',
    height: '72px',
    width: '480px',
    borderRadius: '16px',
    backgroundColor: '#EEEEEE',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  if (!organization) return null;

  return (
    <div>
      <div className='headlineLarge'>
        {organization.name}
      </div>
      <div style={infoContainerStyle}>
        <div
          className="titleMedium"
          style={{ marginLeft: '24px', color: '#999999' }}
        >
          Email:
        </div>
        <div
          className="titleMedium"
          style={{ marginLeft: '5px' }}
        >
          {user ? user.email.length > 45 ? user.email.slice(0, 45) + '...' : user.email : ''}
        </div>
      </div>
      <div style={{
        marginTop: '12px',
        width: '480px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <TextButton
          label={'Reset Password'}
          underlined={true}
          onPress={() => navigate('/password/reset')}
        />
      </div>
      {/* <div style={infoContainerStyle}>
        <div className="titleMedium" style={{marginLeft:'24px', color:'#999999'}}>Password:</div>
        <div className="titleMedium" style={{marginLeft:'5px'}}>Password</div>
      </div> */}
      <div style={infoContainerStyle}>
        <div
          className="titleMedium"
          style={{ marginLeft: '24px', color: '#999999' }}
        >
          Organization ID:
        </div>
        {organization.uid &&
          (<div
            className="titleMedium"
            style={{ marginLeft: '5px' }}
          >
            {organization.uid}
          </div>)
        }
      </div>
      <div style={infoContainerStyle}>
        <div
          className="titleMedium"
          style={{ marginLeft: '24px', color: '#999999' }}
        >
          Timezone:
        </div>
        {organization.timezone &&
          (<div
            className="titleMedium"
            style={{ marginLeft: '5px' }}
          >
            {organization.timezone}
          </div>)
        }
      </div>
      {organization?.address &&
        <div style={infoContainerStyle}>
          <div
            className="titleMedium"
            style={{ marginLeft: '24px', color: '#999999' }}
          >
            Service Address:
          </div>
          <div
            className="titleSmall"
            style={{ marginLeft: '5px' }}
          >
            {organization.address}
          </div>
        </div>
      }
      <div style={infoContainerStyle}>
        <div
          className="titleMedium"
          style={{ marginLeft: '24px', color: '#999999' }}
        >
          Zipcode:
        </div>
        {organization.zipcode &&
          (<div
            className="titleMedium"
            style={{ marginLeft: '5px' }}
          >
            {organization.zipcode}
          </div>)
        }
      </div>
      <div
        style={infoContainerStyle}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleRateSave();
          };
        }}
      >
        <div
          className="titleMedium"
          style={{ marginLeft: '24px', color: '#999999' }}
        >
          Electricity Rate:
        </div>
        <div
          className="titleMedium"
          style={{ marginLeft: '5px' }}
        >
          $
        </div>
        <input
          id="textInput"
          className="titleMedium"
          type="text"
          value={rate}
          onChange={handleRateChange}
          disabled={role === 'view' ? true : isEditing ? false : true} // Disable input based on state
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '240px',
            marginLeft: '5px',
            marginRight: '5px',
            border: '0px'
          }}
        />
        <IconButton
          iconName='pencil-edit'
          tone="light"
          onClick={handleEditButtonClick}
          disabled={role === 'view' ? true : false}
        />
      </div>
      {!billUploaded ?
        <div style={{
          marginTop: '24px',
          width: '480px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <input
            type="file"
            accept="application/pdf,image/jpeg,image/png"
            style={{ display: 'none' }}
            id="fileInput"
            onChange={handleFileChange}
            disabled={role === 'view' ? true : false}
          />
          <CustomButton
            label={'Electricity bill'}
            filled={false}
            iconName={'cloud-upload'}
            iconPosition="left"
            size="medium"
            onClick={handleButtonClick}
            disabled={role === 'view' ? true : false}
          />
        </div>
        :
        <div style={{
          marginTop: '24px',
          width: '480px',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'row'
        }}>
          <CustomButton
            label={'View bill'}
            filled={false}
            iconName={'view'}
            iconPosition="left"
            size="small"
            onClick={downloadFile}
            disabled={role === 'view' ? true : false}
          />
          <input
            type="file"
            accept="application/pdf,image/jpeg,image/png"
            style={{ display: 'none' }}
            id="fileInput"
            onChange={handleFileChange}
            disabled={role === 'view' ? true : false}
          />
          <CustomButton
            label={'Add bill'}
            filled={false}
            iconName={'cloud-upload'}
            iconPosition="left"
            size="small"
            onClick={handleButtonClick}
            disabled={role === 'view' ? true : false}
          />
        </div>
      }
      <div style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '480px',
        marginTop: '15px'
      }}>
        {!wifiSubmitted &&
          <CustomButton
            label={wifiRecieved ? 'Resubmit WiFi Information' : "Add WiFi Information"}
            filled={false}
            iconName={'add'}
            iconPosition="left"
            size={'medium'}
            onClick={openModal}
          />
        }
        <div>
          {wifiSubmitted &&
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
              onClose={() => { setWifiSubmitted(false) }}
              sx={{ borderRadius: '20px' }}
            >
              <AlertTitle>Success</AlertTitle>
              WiFi Information Submitted
            </Alert>
          }
        </div>
      </div>
      <WifiInformationForm
        open={isModalOpen}
        handleClose={closeModal}
        name={organization.name}
        orgID={organization.uid}
        setWifiSubmitted={setWifiSubmitted}
      />
    </div>
  );
};
