import React, { useState, useRef, useEffect } from "react";
import '../../App.css'; // Import your fonts
import { Box, InputBase, Typography, IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import {
  black,
  neutral200,
  neutral300,
  neutral400,
  neutral500,
  neutral600,
  neutral700,
  primary500,
  white,
} from "../../lib/rebrandColors/colors";
import CustomIcon from "../icon";

export default function Dropdown({
  list,
  value = "",
  size = 200,
  setIsDropdownOpen,
  isDropdownOpen,
  onSelect,
  label,
  background = 'dark',
  disabled = false,
}) {
  const listRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClick = (item) => {
    onSelect(item);
    toggleDropdown();
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the list if clicking outside
    };
  };

  // sets up event listener for clicks
  useEffect(() => {
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let height = size < 100 ? '70px' : '60px';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      ref={listRef}
    >
      <div
        className="labelMedium"
        style={{
          color: background === 'dark' ? neutral400 : black,
          paddingLeft: '20px',
          fontSize: '10px',
        }}
      >
        {label}
      </div>
      <button
        onClick={toggleDropdown}
        disabled={disabled}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          // maxWidth: size,
          width: size,
          minWidth: size < 100 ? '80px' : '100px',
          height: size < 100 ? '38px' : '48px',
          padding: size < 100 ? '8px 12px 8px 12px' : '12px 16px 12px 16px',
          gap: '4px',
          borderRadius: '34px',
          opacity: '0px',
          backgroundColor: background === 'dark' ? '#2D2D2D' : '#FAFAFA',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: background === 'dark' ? '0px' : '1px',
          borderColor: background === 'dark' ? '#2D2D2D' : primary500,
        }}>
        <div
          className="buttonMedium"
          style={{
            fontSize: '14px',
            color: isDropdownOpen ? background === 'dark' ? neutral300 : neutral700 : background === 'dark' ? neutral400 : black,
          }}
        >
          {value.length > 20 ? value.slice(0, 20) + '...' : value}
        </div>
        <div style={{
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CustomIcon
            color={isDropdownOpen ? background === 'dark' ? neutral300 : neutral700 : background === 'dark' ? neutral400 : black}
            size={'12px'}
            name='arrow-down-1'
          />
        </div>
      </button>
      {isDropdownOpen && (
        <div style={{
          position: 'absolute',
          marginTop: size > 100 ? label ? '70px' : '50px' : '60px',
          backgroundColor: background === 'dark' ? '#2D2D2D' : '#FAFAFA',
          borderRadius: '16px',
          //padding:'0px 0px 24px 0px',
          display: 'flex',
          justifyContent: 'center',
          // maxWidth: size,
          maxHeight: '376px',
          overflow: 'auto',
          width: size,
          minWidth: '100px',
          gap: '24px',
          boxShadow: '0px 4px 4px 0px #0000004D, 0px 8px 12px 6px #00000026',
          zIndex: 20,
        }}>
          <ul>
            {Array.isArray(list) && list.map((listValue, index) => (
              <li
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleClick(listValue)}
                style={{
                  borderTop: hoveredIndex === index ? '2px solid white' : '2px solid transparent',
                  borderBottom: hoveredIndex === index ? '2px solid white' : '2px solid transparent',
                  opacity: hoveredIndex === index ? '30%' : '100%',
                  padding: '12px 0px 12px 0px',
                  width: '18vw',
                  minWidth: '100px',
                  maxWidth: size - 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  textAlign: 'center'
                }}
              >
                <span style={{
                  color: background === 'dark' ? neutral300 : neutral700,
                  fontSize: '14px',
                  fontWeight: '500'
                }}>
                  {listValue}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
