import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Box, Button, Typography } from "@mui/material";
import { fetchAllOutletsInfo } from "../../functions/allOutletsInfo";
import { green, greenDark, greyDark } from "../../lib/colors";
import OrganizationsList from "../../components/organizationsList";
import OrganizationsDataGridTable from "../../components/organizations-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { fetchAllOrganizationsInfo } from "../../functions/organizations";

export default function OrganizationsScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [outlets, setOutlets] = useState([]);
  const navigate = useNavigate();
  const orgID = localStorage.getItem("orgID");
  const [organizations, setOrganizations] = useState([]);
  useEffect(() => {
    if (waiting) {
      return;
    }
    // console.log(user.email);
    if (!user) navigate("/login");
  }, [user, waiting]);

  useEffect(() => {
    const getOrganizations = async () => {
      setLoading(true);
      const outletsData = await fetchAllOutletsInfo();
      //console.log("Fetched outlets data: ", outletsData);

      // get the unique orgIDs from the outletsData array of objects
      let orgNames = outletsData.map((obj) => obj.orgName);
      // Step 2: Use a Set to get unique values
      let uniqueOrgs = new Set(orgNames);
      // Step 3: Convert Set back to an array
      uniqueOrgs = [...uniqueOrgs].sort();
      const totalOrgData = [];
      for (let org in uniqueOrgs) {
        let orgHelperData = {};
        let orgOutlets = outletsData.filter(
          (obj) => obj.orgName === uniqueOrgs[org]
        );
        orgHelperData["name"] = uniqueOrgs[org];
        orgHelperData["total"] = orgOutlets.length;
        let connected = 0;
        let disconnected = 0;
        let notActivated = 0;
        let activeSchedule = 0;
        for (let outlet in orgOutlets) {
          let wifiStatus = orgOutlets[outlet].wifiStatus;
          if (wifiStatus == "Connected") {
            connected += 1;
          } else if (wifiStatus == "Disconnected") {
            disconnected += 1;
          } else {
            notActivated += 1;
          }
          let scheduleStatus = orgOutlets[outlet].scheduleStatus;
          if (scheduleStatus == "Active") {
            activeSchedule += 1;
          }
        }
        orgHelperData["connected"] = connected;
        orgHelperData["disconnected"] = disconnected;
        orgHelperData["notActivated"] = notActivated;
        orgHelperData["active"] = activeSchedule;
        // console.log(orgHelperData)
        totalOrgData.push(orgHelperData);
      }

      setOutlets(outletsData);
      //   setOrgData(totalOrgData);
      setLoading(false);
    };

    getOrganizations();
  }, []);

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    setLoading(true);
    let orgs = await fetchAllOrganizationsInfo();
    setOrganizations(orgs);
    setLoading(false);
  };

  const registerNewOrganization = () => {
    // Pass the current location (or page) as state
    navigate("/organizations/register", {
      state: { from: window.location.pathname },
    });
  };
  return (
    <>
      {orgID === "LZqI3R6MInUuwtgtROPK" && (
        <View
          style={{
            backgroundColor:'#F2F4F8',
            minHeight:'100vh'
          }}
        >
          {/* <Header orgID={orgID} /> */}
          <div style={{marginTop:'50px', display:'flex', alignItems:'center', flexDirection:'column'}}>
          <Typography
            variant="h5"
            component="div"
            sx={{ mb: 2, alignSelf: "center" }}
          >
            Admin Organizations Table
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Align button to the right
              width: "915px", // Ensure it aligns with the organization cards
              margin: "0 auto 20px auto", // Center the button and give some space below
            }}
          >
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              sx={{
                backgroundColor: green,
                "&:hover": {
                  backgroundColor: greenDark,
                },
              }}
              onClick={registerNewOrganization}
            >
              New
            </Button>
          </Box>
          <OrganizationsDataGridTable
            data={organizations}
            loading={loading}
            getOrganizations={getOrganizations}
          />
          <OrganizationsList></OrganizationsList>
          </div>
        </View>
      )}
    </>
  );
}
