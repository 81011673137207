import {
    neutral800,
    primary500,
    white,
} from "../../lib/rebrandColors/colors";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: '#FAFAFA',
        borderRadius: "24px",
        alignContent: "center",
        width: 564,
        height: 700,
        overflowY: 'scroll',
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        gap: "20px",
        justifyContent: 'space-evenly',
        alignItems: 'center',
        overflowY: 'scroll',
        height: '100%',
        marginBottom: '30px',
    },
    headerContainer: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerText: {
        color: '#1F1F1F', 
        textAlign: "Left" 
    }
  };
  
export default styles;