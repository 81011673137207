import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { fetchOrganizationInfoFromID } from '../../../functions/organizations';
import {
    fetchSignInMethodsForEmail,
    getAuth,
    createUserWithEmailAndPassword,
    signOut,
} from "@firebase/auth";
import CustomButton from '../../../components/customButton';
import TextField from '../../../components/textField';
import { useNavigate } from "react-router-dom";
import { registerNewUser, addUserToOrganization } from "../../../functions/users";
import { neutral800, white } from "../../../lib/rebrandColors/colors";
import IconButton from "../../iconButton";
import styles from "../styles";

export default function AddUserForm({
    open,
    handleClose,
    orgID,
    setSuppressNavigation,
}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailConfirmError, setEmailConfirmError] = useState(false);
    const [orgName, setOrgName] = useState("");
    const navigate = useNavigate();
    const handleUsersCreation = async () => {
        const auth = getAuth();
        const newAuth = getAuth();
        let users = [];

        setSuppressNavigation(true);
        // Determine the role based on the referring page
        const role = "member";
        try {
            const currentUser = auth.currentUser;

            const userCredential = await createUserWithEmailAndPassword(
                newAuth,
                email,
                password
            );
            const user = userCredential.user;
            await registerNewUser(user, orgID, role); // Pass the determined role ("pending" or "admin")
            await addUserToOrganization(user.uid, orgID);
            await signOut(auth);

            await auth.updateCurrentUser(currentUser);

            // Show success alert
            alert('User successfully created!');
            setTimeout(() => {
                navigate("/account",)
            }, 1500);

            setSuppressNavigation(false);
        } catch (error) {
            console.error(`Error creating user with email ${email}:`, error);
            alert('ERROR! Createing user failed');
            return false;
        } finally {
            setSuppressNavigation(false);
        };
        return users;
    };
    const handleEmailChange = (value) => {
        // const value = e.target.value;

        // Basic email regex pattern:
        // - Starts with one or more characters (letters, numbers, dots, underscores, or hyphens)
        // - Must contain the @ symbol
        // - Domain name part with one or more characters
        // - Dot (.) followed by a valid top-level domain (2-6 characters)
        const isValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/.test(value);

        if (isValid) {
            setEmail(value);
            setEmailError(false);
        } else {
            setEmailError(true);
        };
    };
    const handlePasswordChange = (value) => {
        // const value = e.target.value;

        // Regex checks for:
        // - Minimum 8 characters
        // - At least one uppercase letter
        // - At least one lowercase letter
        // - At least one digit
        // - At least one special character
        const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value);

        if (isValid) {
            setPassword(value);
            setPasswordError(false);
        } else {
            setPasswordError(true);
        };
    };
    const handleConfirmPasswordChange = (value) => {
        // const value = e.target.value;
        const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value);
        const passwordValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(password);

        if (isValid && passwordValid) {
            setConfirmPassword(value);
            setConfirmPasswordError(false);
        } else {
            setConfirmPasswordError(true);
        };
    };
    const handleConfirmEmail = async () => {
        try {
            const auth = getAuth();
            const signInMethods = await fetchSignInMethodsForEmail(auth, email);

            if (signInMethods.length === 0) {
                setEmailConfirmError(false);
                return true; // Email is valid and not in use
            } else {
                setEmailConfirmError(true);
                setEmailError(true);
                return false; // Email is already in use
            };
        } catch (error) {
            console.error("Error confirming email:", error);
            setEmailConfirmError(true);
            return false;
        };
    };
    const handleSubmit = async () => {
        // Validate before submission
        const emailIsValid = await handleConfirmEmail();
        if (!emailIsValid) {
            //setProcessing(false); // Stop processing if email is invalid
            return; // Exit early if email validation fails
        };

        try {
            let users = await handleUsersCreation();
        } catch (error) {
            console.error("Error registering new member:", error);
        };
    };

    const handleClickClose = () => {
        handleClose();
    };

    useEffect(() => {
        async function getOrgName() {
            const orgDoc = await fetchOrganizationInfoFromID(orgID);
            setOrgName(orgDoc.name);
            // console.log(orgDoc.name);
        };
        getOrgName();
    }, [orgID]);

    return (
        <Modal open={open} onClose={handleClickClose}>
            <Box sx={styles.container}>
                <div style={{ ...styles.innerContainer, justifyContent: 'flex-start' }}>
                    {/* close */}
                    <div style={styles.headerContainer}>
                        <div
                            className="titleLarge"
                            style={styles.headerText}
                        >
                            Register member for {orgName}
                        </div>
                        <IconButton
                            iconName='cancel-1'
                            tone='dark'
                            size={24}
                            onClick={handleClickClose}
                        />
                    </div>
                    {/* registration */}
                    <div style={{
                        alignSelf: 'center'
                    }}>
                        <>
                            <div style={{ height: '100px' }}>
                                <TextField
                                    label="Email"
                                    placeholder="help@reverttechnologies.com"
                                    size="medium"
                                    onChange={handleEmailChange}
                                    descriptionText={
                                        emailError
                                            ? emailConfirmError ? 'Email already in use'
                                                : 'Please enter a valid email'
                                            : ''
                                    }
                                    textError={emailError || emailConfirmError ? true : false}
                                    background="light"
                                />
                            </div>
                            <div style={{ height: '100px' }}>
                                <TextField
                                    label="Password"
                                    placeholder="password"
                                    visibility={true}
                                    size="medium"
                                    onChange={handlePasswordChange}
                                    descriptionText={passwordError ? 'Passwords require upper and lower case letters, a number, and a special character' : ''}
                                    textError={passwordError ? true : false}
                                    background="light"
                                />
                            </div>
                            <div style={{
                                marginBottom: '30px',
                                height: '70px'
                            }}>
                                <TextField
                                    label="Confirm Password"
                                    placeholder="password"
                                    visibility={true}
                                    size="medium"
                                    onChange={handleConfirmPasswordChange}
                                    descriptionText={confirmPasswordError ? 'Passwords must match' : ''}
                                    textError={confirmPasswordError ? true : false}
                                    background="light"
                                />
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: '10px'
                            }}>
                                <CustomButton
                                    label="Register member"
                                    filled={true}
                                    custFontSize={16}
                                    iconName={'arrow-right'}
                                    size="medium"
                                    onClick={handleSubmit}
                                    disabled={
                                        (emailError || emailConfirmError || passwordError || confirmPasswordError) || (orgID === '' || email === '' || password === '' || confirmPassword === '')
                                            ? true : false
                                    }
                                />
                            </div>
                        </>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};