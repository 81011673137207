import React, { useState, useEffect } from "react";
import {
  Box,
} from "@mui/material";
import '../../App.css'; // Import your fonts
import IconButton from "../iconButton";
import CustomIcon from "../icon";
import TextButton from "../textButton";
import { fetchNotifications, updateNotifications } from "../../functions/notifications"

const filteredNotifications = [
  { 'title': 'Placeholder Task', 'description': 'This is for testing', 'status': 'unread', 'dateCreated': '2024-11-12' },
  { 'title': 'Placeholder Task 2', 'description': 'This is for testing', 'status': 'unread', 'dateCreated': '2024-11-12' },
  { 'title': 'Placeholder Task 3', 'description': 'This is for testing', 'status': 'unread', 'dateCreated': '2024-11-12' }
];

export default function NotificationsModal({
  orgID,
  selectedOrgID,
  open,
  handleClose,
}) {
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  //const [notification, setNotification] = useState({status:'unread'})

  //TODO: FILTER NOTIFICATIONS BY DATE
  //TODO: HAVE CHILD ORG NOTIFICATIONS BE AVAILABLE
  useEffect(() => {
    if (orgID) {
      if (orgID == 'LZqI3R6MInUuwtgtROPK') {
        if (selectedOrgID) {
          fetchNotifications([selectedOrgID], setNotifications, setNotificationsLoading)
        };
      } else {
        fetchNotifications([orgID], setNotifications, setNotificationsLoading)
      };
    };
  }, [notificationsLoading, selectedOrgID]);


  useEffect(() => {
    let sortedNotifications = notifications.filter(notification => notification.status === "unread");
    setFilteredNotifications(sortedNotifications)
  }, [notifications]);


  const handleNotificationUpdate = (notification, value) => {
    updateNotifications(notification.id, value)
    setNotificationsLoading(true)
  };

  return (
    <div>
      {open &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "79px",
            left: "70%",
            //transform: "translate(-50%, -50%)",
            bgcolor: '#F9F9F9',
            borderRadius: "24px",
            alignContent: "center",
            width: '464px',
            maxHeight: '926px',
            minHeight: '250px',
            border: '1px solid #999999',
            zIndex: 10,
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '24px',
            marginLeft: '24px',
            justifyContent: 'center',
            //alignItems: 'center'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
              <div
                className="titleSmall"
                style={{ color: '#4B4B4B', textAlign: "Left", fontSize: '16px' }}
              >
                Notifications
              </div>
              <div style={{ marginRight: '24px' }}>
                <IconButton tone='light' iconName='cancel-1' size={24} onClick={handleClose} />
              </div>
            </div>
            {filteredNotifications.length > 0 ?
              filteredNotifications.map((notification, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      //width: '100%',
                      minHeight: '88px',
                      //backgroundColor: '#EEEEEE',
                      justifyContent: 'flex-start',
                      //marginRight:'5px',
                      marginTop: '16px',
                      alignSelf: 'flex-start',
                      marginBottom: '10px'
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: '#F1F1F1',
                      }}
                      >
                        <CustomIcon
                          name='clock'
                          size={24}
                          color='#9B060E'
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
                        <div className="titleSmall"
                          style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            color: '#4B4B4B'
                          }}
                        >
                          {notification.title}
                        </div>
                        <div className="titleSmall"
                          style={{
                            fontSize: '12px',
                            color: '#999999',
                            marginTop: '4px'
                          }}
                        >
                          {notification.description}
                        </div>
                        <div className="titleSmall"
                          style={{
                            fontSize: '12px',
                            color: '#999999',
                            marginTop: '24px',
                            lineHeight: '16px'
                          }}
                        >
                          {notification.dateCreated}
                        </div>
                        {notification.status == 'unread' ?
                          <div style={{ marginTop: '4px' }}>
                            <TextButton label='Mark as read' fontSize='14px' onPress={() => handleNotificationUpdate(notification, 'read')} />
                          </div>
                          :
                          <div style={{ marginTop: '4px' }}>
                            <TextButton label='Mark as unread' fontSize='14px' onPress={() => handleNotificationUpdate(notification, 'unread')} />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <div
                className="headlineMedium"
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  textAlign: 'center',
                  marginTop: '50px',
                  color: '#999999'
                }}
              >
                No new notifications
              </div>
            }
          </div>
        </Box>
      }
    </div>
  );
};