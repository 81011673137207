// Import SVGs as React Components
import { ReactComponent as HorLogoBlack } from '../../assets/images/logos/RVT-Hor-Logo-Black.svg';
import { ReactComponent as HorLogoNeg } from '../../assets/images/logos/RVT-Hor-Logo-Neg.svg';
import { ReactComponent as HorLogoPos } from '../../assets/images/logos/RVT-Hor-Logo-Pos.svg';
import { ReactComponent as HorLogoWhite } from '../../assets/images/logos/RVT-Hor-Logo-White.svg';
import { ReactComponent as HorLogoNegBlack } from '../../assets/images/logos/RVT-Hor-Logo-Neg-Black.svg';
import { ReactComponent as StackedLogoNegBlack } from '../../assets/images/logos/RVT-Stacked-Logo-Neg-Black.svg';
import { ReactComponent as StackedLogoBlack } from '../../assets/images/logos/RVT-Stacked-Logo-Black.svg';
import { ReactComponent as StackedLogoNeg } from '../../assets/images/logos/RVT-Stacked-Logo-Neg.svg';
import { ReactComponent as StackedLogoPos } from '../../assets/images/logos/RVT-Stacked-Logo-Pos.svg';
import { ReactComponent as StackedLogoWhite } from '../../assets/images/logos/RVT-Stacked-Logo-White.svg';

import { ReactComponent as SymbolBlack } from '../../assets/images/symbols/RVT-Symbol-Black.svg';
import { ReactComponent as SymbolNegBlack } from '../../assets/images/symbols/RVT-Symbol-Neg-Black.svg';
import { ReactComponent as SymbolNeg } from '../../assets/images/symbols/RVT-Symbol-Neg.svg';
import { ReactComponent as SymbolPos } from '../../assets/images/symbols/RVT-Symbol-Pos.svg';
import { ReactComponent as SymbolWhite } from '../../assets/images/symbols/RVT-Symbol-White.svg';

// CustomLogo component
export default function CustomLogo({
  name = 'horizontal-black',
  height,
  width = '160px',
}) {
  // Logo mapping object
  const logos = {
  'horizontal-black': HorLogoBlack,
  'horizontal-negative-black': HorLogoNegBlack,
  'horizontal-negative': HorLogoNeg,
  'horizontal-positive': HorLogoPos,
  'stacked-black': StackedLogoBlack,
  'stacked-negative-black': StackedLogoNegBlack,
  'stacked-negative': StackedLogoNeg,
  'stacked-positive': StackedLogoPos,
  'stacked-white': StackedLogoWhite,
  'symbol-black': SymbolBlack,
  'symbol-negative-black': SymbolNegBlack,
  'symbol-negative': SymbolNeg,
  'symbol-positive': SymbolPos,
  'symbol-white': SymbolWhite,
  'horizontal-white': HorLogoWhite,
};
  const SelectedLogo = logos[name];
  if (!SelectedLogo) {
    console.warn(`Logo "${name}" not found`);
    return null;
  };
  return (
    <div style={{ width, height }}>
      <SelectedLogo width={width} height={height} />
    </div>
  );
};