import db from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

// Fetch events for a specific organization
export const fetchTasks = async (orgIDs, setTasks, setTasksLoading) => {
  const tasksQuery = query(
    collection(db, "tasks"),
    where("orgID", "in", orgIDs)
  );
  const tasksSnapshot = await getDocs(tasksQuery);
  const tasks = tasksSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  
  setTasks(tasks);
  setTasksLoading(false);
};

// Delete a specific event
export const updateTasks = async (taskID, status) => {
  try {
    const taskRef = doc(db, "tasks", taskID);
    if (taskRef) {
      await updateDoc(taskRef, {
        status: status,
      });
      console.log("Task successfully updated!");
    }
  } catch (error) {
    console.error("Error updating event: ", error);
  }
};

