import React, { useEffect, useState } from "react";
import {
  fetchOrganizationInfoFromID,
} from "../../functions/organizations";
import { fetchOutlets } from "../../functions/outlets";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import OrganizationPicker from "../../components/orgPicker";
import OutletList from "../../components/outletList";
import { fetchSavings } from "../../functions/savings";
import { useOutletContext } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function ApplianceScreen() {
  // from layout
  const {
    orgID,
    orgName,
    isOverviewAccount,
    selectedOrgID,
    // child_orgs,
    timezone,
    allProdTypes,
    allOrgs,
    setTimezone,
    setRate,
    setSelectedOrgID,
    // setChild_Orgs
  } = useOutletContext();
  const [child_orgs, setChild_Orgs] = useState(localStorage.getItem("child_orgs") || []);
  const [outlets, setOutlets] = useState([]);
  const [update, setUpdate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [wattHours, setWattHours] = useState([]);
  const [savings, setSavings] = useState([]);
  const [savingsLoading, setSavingsLoading] = useState(true);
  //const [prodTypes, setProdTypes] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [averageUsage, setAverageUsage] = useState([]);
  const [averageLoading, setAverageLoading] = useState(true);
  // const [allProdTypes, setAllProdTypes] = useState(localStorage.getItem("productTypes") || []);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // This is for filtering
  const [selectedApplianceType, setSelectedApplianceType] = useState("");
  const handleApplianceTypeChange = (event) => {
    const selectedApplianceType = event.target.value;
    setSelectedApplianceType(selectedApplianceType);
    if (selectedOrgID !== "") {
      fetchWattHours(
        selectedOrgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
    } else {
      fetchWattHours(
        orgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
    }
  };

  // get the following information from org id or selected org id
  // - child orgs, schedules, watt hours, tasks
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      //get the (selected) org document information from the firebase
      let orgData = []
      let orgNameArray = []
      if (selectedOrgID !== "") {
        orgData = await fetchOrganizationInfoFromID(selectedOrgID);
        setOrgData([orgData]);
      } else if (orgID !== "") {
        orgData = await fetchOrganizationInfoFromID(orgID);
        setOrgData([orgData]);
      };

      //if the (selected) org has child orgs make sure to update the child orgs in storage
      if (orgData.child_orgs) {
        setChild_Orgs(orgData.child_orgs);
        localStorage.setItem('child_orgs', orgData.child_orgs);
        if (orgData.child_orgs.length > 0) {
          const orgDataPromises = orgData.child_orgs.map(org => fetchOrganizationInfoFromID(org));
          const orgDataArray = await Promise.all(orgDataPromises);
          setOrgData(orgDataArray)
        };
      } else {
        setChild_Orgs([]);
        localStorage.setItem('child_orgs', []);
      };

      //get schedules and wattHours
      if (orgData.child_orgs) {
        if (orgData.child_orgs.length > 0) {
          fetchWattHours(orgData.child_orgs, setWattHours, setWattsLoading);
          fetchSavings(orgData.child_orgs, setSavings, setSavingsLoading);
          fetchAverageUsage(orgData.child_orgs, setAverageUsage, setAverageLoading);
        };
      } else if (selectedOrgID !== "") {
        fetchWattHours([selectedOrgID], setWattHours, setWattsLoading);
        fetchSavings([selectedOrgID], setSavings, setSavingsLoading);
        fetchAverageUsage([selectedOrgID], setAverageUsage, setAverageLoading);
      } else {
        if (orgID !== '') {
          fetchWattHours([orgID], setWattHours, setWattsLoading);
          fetchSavings([orgID], setSavings, setSavingsLoading);
          fetchAverageUsage([orgID], setAverageUsage, setAverageLoading);
        };
      };
    };
    fetchDashboardInfo();
  }, [orgID, selectedOrgID, update]);

  // Sets update every 2 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(!update)
    }, 120000); // 120000ms = 120 seconds
    return () => {
      clearInterval(interval);
    };
  }, [update]);

  // fetches Outlets and Savings
  useEffect(() => {
    setOutletsLoading(true);
    
    const fetchTimezone = localStorage.getItem('timezone');
    // NOTE: changed from timezone to fetched timezone as it wasn't updating correctly
    if (selectedOrgID !== "" && child_orgs.length == 0) {
      
      fetchOutlets([selectedOrgID], fetchTimezone, setOutlets, setOutletsLoading);
      fetchSavings([selectedOrgID], setSavings, setSavingsLoading);
    } else if (child_orgs.length > 0) {
      if (Array.isArray(child_orgs)) {
        fetchOutlets(child_orgs, fetchTimezone, setOutlets, setOutletsLoading);
        fetchSavings(child_orgs, setSavings, setSavingsLoading);
      } else {
        fetchOutlets(child_orgs.split(','), fetchTimezone, setOutlets, setOutletsLoading);
        fetchSavings(child_orgs.split(','), setSavings, setSavingsLoading);
      };
    } else {
      fetchOutlets([orgID], fetchTimezone, setOutlets, setOutletsLoading);
      fetchSavings([orgID], setSavings, setSavingsLoading);
    };
  }, [selectedOrgID, update, modalEdit, orgData, orgID]);

  // resize function useEffect
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Render based on loading state
  return (
    <div style={{
      display: "flex",
      backgroundColor: '#1F1F1F',
      height: 'auto',
      minHeight: '100vh',
      minWidth: '1300px',
      justifyContent: 'center',
      marginTop: '86px'
    }}>
      <div style={{
        marginTop: '30px',
        display: "flex",
        flexDirection: "column",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: 'space-between'
        }}>
          <div
            className="headlineLarge"
            style={{
              width: "350px",
              height: "auto",
              display: "block",
              alignSelf: 'start',
              marginTop: '10px',
              color: '#FFFFFF',
            }}
          >
            Appliances
          </div>
          {(isOverviewAccount === 'true' || orgID == 'LZqI3R6MInUuwtgtROPK') &&
            <OrganizationPicker
              allOrgs={allOrgs}
              orgID={orgID}
              selectedOrgID={selectedOrgID}
              setSelectedOrgID={setSelectedOrgID}
              setTimezone={setTimezone}
              setRate={setRate}
              label={true}
            />
          }
        </div>
        <div style={{
          marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
          display: "flex",
          flexDirection: "row",
          marginBottom: '30px'
        }}>
          <div style={{
            width: "1136px",
            display: "flex",
            borderRadius: '24px',
            minHeight: '792px',
            backgroundColor: '#FAFAFA',
            maxHeight: '792px',
            overflowY: 'hidden',
            marginRight: '16px',
            justifyContent: 'center'
          }}>
            {savingsLoading || outletsLoading || wattsLoading ? (
              <CircularProgress />)
              :
              <OutletList
                outlets={outlets}
                setOutlets={setOutlets}
                width={'1036px'}
                height={725}
                wattHours={wattHours}
                savings={savings}
                orgData={orgData}
                loading={outletsLoading}
                prodTypes={allProdTypes}
                modalEdit={modalEdit}
                setModalEdit={setModalEdit}
                averageUsage={averageUsage}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};