import db from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

// Fetch schedules for specific organizations with optional outlet filtering
export const fetchOrgSchedules = async (orgIDs, setSchedules, setSchedulesLoading, outletID = null) => {
  try {
    const schedulesQuery = query(
      collection(db, "schedules"),
      where("orgID", "in", orgIDs)
    );
    const schedulesSnapshot = await getDocs(schedulesQuery);

    let schedules = schedulesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Filter schedules by outletID if provided
    if (outletID) {
      schedules = schedules.filter((schedule) =>
        schedule.outletsSelected.includes(outletID)
      );
    }

    setSchedules(schedules);
  } catch (error) {
    console.error("Error fetching schedules: ", error);
    setSchedules([]);
  } finally {
    if (setSchedulesLoading) {
      setSchedulesLoading(false);
    }
  }
};

// Delete a specific schedule
export const deleteOrgSchedule = async (scheduleID) => {
  try {
    const scheduleRef = doc(db, "schedules", scheduleID);
    if (scheduleRef) {
      await deleteDoc(scheduleRef);
      console.log("Schedule successfully deleted!");
    }
  } catch (error) {
    console.error("Error deleting schedule: ", error);
  }
};

// Update the status of a schedule
export const updateStatus = async (scheduleID, status) => {
  try {
    const scheduleRef = doc(db, "schedules", scheduleID);
    await updateDoc(scheduleRef, {
      status: status,
    });

    console.log("Schedule status successfully updated!");
  } catch (error) {
    console.error("Error updating schedule status: ", error);
  }
};

// Create a new schedule
export const createSchedule = async (scheduleData) => {
  try {
    const schedule = {
      type: "Schedule",
      status: scheduleData.status,
      dateCreated: scheduleData.dateCreated || new Date().toISOString().split("T")[0], 
      missionId: scheduleData.missionId || "", 
      names: scheduleData.names || [], 
      outletsSelected: scheduleData.outletsSelected || [], 
      repeat: scheduleData.repeat || [], 
      onHour: scheduleData.onHour || 0, 
      onMinute: scheduleData.onMinute || 0, 
      offHour: scheduleData.offHour || 0, 
      offMinute: scheduleData.offMinute || 0, 
      potentialEnergySavings: scheduleData.potentialEnergySavings || 0, 
      potentialPriceSavings: scheduleData.potentialPriceSavings || 0, 
      potentialCO2Savings: scheduleData.potentialCO2Savings || 0, 
      events: scheduleData.events || [], 
      lastExecutionDate: scheduleData.lastExecutionDate || "", 
      orgID: scheduleData.orgID || ""
    };
    const docRef = await addDoc(collection(db, "schedules"), schedule);
    console.log("Schedule successfully created with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error creating schedule:", error);
    throw error;
  }
};

export const handleCreateSchedule = async (formData, selectedOrgID, orgID, setSchedules) => {
  const organizationID = selectedOrgID ? selectedOrgID : orgID
  try {
    const scheduleID = await createSchedule({
      status: "New",
      dateCreated: new Date().toISOString().split("T")[0],
      missionId: formData.missionId,
      names: formData.names || [],
      outletsSelected: formData.outletsSelected || [],
      repeat: formData.repeat || [],
      onHour: formData.onHour || 0,
      onMinute: formData.onMinute || 0,
      offHour: formData.offHour || 0,
      offMinute: formData.offMinute || 0,
      potentialEnergySavings: 0,
      potentialPriceSavings: 0,
      potentialCO2Savings: 0,
      events: [],
      lastExecutionDate: "",
      orgID: organizationID
    });
    await updateStatus(scheduleID, "Accepted");
    const newSchedule = {
      id: scheduleID,
      status: "Accepted",
      dateCreated: new Date().toISOString().split("T")[0],
      missionId: formData.missionId,
      names: formData.names || [],
      outletsSelected: formData.outletsSelected || [],
      repeat: formData.repeat || [],
      onHour: formData.onHour || 0,
      onMinute: formData.onMinute || 0,
      offHour: formData.offHour || 0,
      offMinute: formData.offMinute || 0,
      potentialEnergySavings: 0,
      potentialPriceSavings: 0,
      potentialCO2Savings: 0,
      events: [],
      lastExecutionDate: "",
      orgID: organizationID
    };

    // Update the schedules
    setSchedules((prevSchedules) => [...prevSchedules, newSchedule]);
    console.log("Schedule created successfully with ID:", scheduleID);
  } catch (error) {
    console.error("Failed to create schedule:", error);
  }
};

export const handleEditSubmit = async (editData, refresh, setRefresh) => {
  try {
    // NOTE: Update to schedules collection
    const scheduleRef = doc(db, "schedules", editData.id);
    await updateDoc(scheduleRef, {
      missionId: editData.missionId,
      offHour: editData.offHour,
      offMinute: editData.offMinute,
      onHour: editData.onHour,
      onMinute: editData.onMinute,
      outletsSelected: editData.outletsSelected,
      repeat: editData.repeat,
    });
    console.log("doc updated successfully");
    setRefresh(!refresh);
  } catch (error) {
    console.log("error updating", error);
  }
};

// function to handle deleting an event
export const handleScheduleDeleteSubmit = async (scheduleID, refresh, setRefresh) => {
  try {
    const scheduleDocRef = doc(db, "schedules", scheduleID);
    await deleteDoc(scheduleDocRef);

    console.log(`Schedule with ID ${scheduleID} deleted successfully!`);
    setRefresh(!refresh);
  } catch (error) {
    console.log("Error deleting event:", error);
  }
};