import {
  collection,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  getDoc
} from "firebase/firestore";
import db from "../firebase";

export const fetchLifetimeSavings = async (
  orgID,
  setLifeCO2eSavings,
  setLifeCostSavings,
  setLifeEnergySavings,
  rate
) => {
  const getLifetimeSavings = async () => {
    const lifetimeQ = query(
      collection(db, "organizations"),
      where("uid", "==", orgID)
    );

    const lifetimeSnapshot = await getDocs(lifetimeQ);
    lifetimeSnapshot.forEach((doc) => {
      setLifeCO2eSavings(doc.data()["rev_co2eSavings"]);
      setLifeCostSavings((doc.data()["rev_energySavings"] / 1000) * rate);
      setLifeEnergySavings(doc.data()["rev_energySavings"]);
    });
  };
  getLifetimeSavings();
};

export const fetchMonthlySavings = async (
  orgID,
  setMonthlyCO2eSavings,
  setMonthlyCostSavings,
  setMonthlyEnergySavings,
  year,
  month,
  rate,
  outletID = null
) => {
  const getMonthlySavings = async () => {
    const co2eSavingsArray = [];
    const costSavingsArray = [];
    const energySavingsArray = [];
    let monthQ;
    if (outletID) {
      monthQ = query(
        collection(db, "savings"),
        where("orgID", "==", orgID),
        where("date", "==", `${year}-${month}-01 00:00:00`),
        where("outletID", "==", outletID)
      );
    } else {
      monthQ = query(
        collection(db, "savings"),
        where("orgID", "==", orgID),
        where("date", "==", `${year}-${month}-01 00:00:00`)
      );
    }
    const monthSnapshot = await getDocs(monthQ);
    monthSnapshot.forEach((doc) => {
      co2eSavingsArray.push({
        [doc.data().outletID]: doc.data().co2eSavings,
      });
      costSavingsArray.push({
        [doc.data().outletID]: (doc.data().energySavings / 1000) * rate,
      });
      energySavingsArray.push({
        [doc.data().outletID]: doc.data().energySavings,
      });
    });
    setMonthlyCO2eSavings(co2eSavingsArray);
    setMonthlyCostSavings(costSavingsArray);
    setMonthlyEnergySavings(energySavingsArray);
  };
  getMonthlySavings();
};

// This function was only used once, to update the document one time, but I will leave it here, just in case
export const fetchTotalSavingsAndUpdate = async () => {
  const getTotalSavings = async () => {
    const savingsSnapshot = await getDocs(collection(db, "savings"));

    let totalCO2eSavings = 0;
    let totalCostSavings = 0;
    let totalEnergySavings = 0;

    savingsSnapshot.forEach((doc) => {
      const data = doc.data();
      totalCO2eSavings += data.co2eSavings || 0;
      totalCostSavings += data.costSavings || 0;
      totalEnergySavings += data.energySavings || 0;
    });

    // Update the global/organizations document
    const orgDocRef = doc(db, "global", "organizations");
    await updateDoc(orgDocRef, {
      rev_co2eSavings: totalCO2eSavings,
      rev_costSavings: totalCostSavings,
      rev_energySavings: totalEnergySavings,
    });

    // Return the accumulated totals as a JSON object
    return {
      rev_co2eSavings: totalCO2eSavings,
      rev_costSavings: totalCostSavings,
      rev_energySavings: totalEnergySavings,
    };
  };

  return await getTotalSavings();
};

export const fetchGlobalSavings = async () => {
  const getSavings = async () => {
    const orgDocRef = doc(db, "global", "organizations");
    const orgDocSnapshot = await getDoc(orgDocRef);

    if (orgDocSnapshot.exists()) {
      const data = orgDocSnapshot.data();
      return {
        rev_co2eSavings: data.rev_co2eSavings || 0,
        rev_costSavings: data.rev_costSavings || 0,
        rev_energySavings: data.rev_energySavings || 0,
      };
    } else {
      // Handle case where document does not exist
      return {
        rev_co2eSavings: 0,
        rev_costSavings: 0,
        rev_energySavings: 0,
      };
    };
  };

  return await getSavings();
};

export const fetchOutletSavings = async (outletID) => {
  const getSavings = async () => {
    let co2eSavings = 0;
    let costSavings = 0;
    let energySavings = 0;
    const outletQ = query(collection(db, 'savings'), where('outletID', '==', outletID));
    const outletSnapshot = await getDocs(outletQ);

    outletSnapshot.forEach((doc) => {
      co2eSavings += doc.data().co2eSavings
      costSavings += doc.data().costSavings//(doc.data().energySavings / 1000) * rate,
      energySavings += doc.data().energySavings
    });
    return [co2eSavings, costSavings, energySavings]
  };

  return await getSavings();
};

export const fetchSavings = (
  orgIDs,
  setSavings,
  setSavingsLoading,
  outletID = null,
  applianceType = null
) => {
  let savings = [];
  let savingsQ;
  //get new wattHours collection
  const getSavings = async () => {
    if (outletID) {
      // ----------------> If there is an outletID, we need to filter
      savingsQ = query(
        collection(db, "savings"),
        where("orgID", 'in', orgIDs),
        where("outletID", "==", outletID)
      );
    } else {
      savingsQ = query(
        collection(db, "savings"),
        where("orgID", 'in', orgIDs),
      );
    };
    const savingsQuerySnapshot = await getDocs(savingsQ);

    savingsQuerySnapshot.forEach((doc) => {
      savings.push(doc.data());
    });

    setSavings(savings);
    setSavingsLoading(false);
    // console.log('savings function', savings);
  };
  getSavings();
};


export const fetchTotalCostSavings = async (orgID) => {
  try {
    const savingsQuery = query(
      collection(db, "savings"),
      where("orgID", "==", orgID)
    );

    const savingsSnapshot = await getDocs(savingsQuery);
    let totalCostSavings = 0;

    savingsSnapshot.forEach((doc) => {
      const data = doc.data();
      totalCostSavings += data.costSavings || 0;
    });

    return totalCostSavings;
  } catch (error) {
    console.error("Error fetching cost savings:", error);
    return 0; // Return 0 if there's an error
  };
};

/**
 * Calculate the projected savings for an outlet based on off hours and repeat days.
 * @param {string} outletID - The ID of the outlet.
 * @param {number} onHour - The hour the outlet turns on.
 * @param {number} offHour - The hour the outlet turns off.
 * @param {Array<string>} repeat - Days the schedule applies to (e.g., ["Mon", "Tue"]).
 * @returns {Promise<number>} - The total projected savings.
 */
export const calculateProjectedSavings = async (outletID, onHour, onMinute, offHour, offMinute, repeat, rate) => {
  // console.log("[calculateProjectedSavings]", onHour, offHour)
  try {
    // Query the averageUsage collection for a document with the matching outletID field
    const q = query(
      collection(db, "averageUsage"),
      where("outletID", "==", outletID)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn(`No data found for outletID: ${outletID}`);
      return 0;
    };

    // Assume there's only one matching document
    const docSnapshot = querySnapshot.docs[0];
    const data = docSnapshot.data();

    if (!data || !data.average_savings_data) {
      console.warn(`No average_savings_data found for outletID: ${outletID}`);
      return 0;
    };

    const { average_savings_data } = data;

    //Get the utility rate for the organization
    // Reference to the specific org document in Firestore
    const orgDocRef = doc(db, "organizations", data.orgID);
    // Get the document snapshot
    const orgDocSnap = await getDoc(orgDocRef);
    let rate = 0.16; //default $0.16/kwH if no rate is provided
    if (orgDocSnap.exists()) {
      // Document exists, set the data to state
      rate = orgDocSnap.data().rate;
    } else {
      // Document not found
      console.warn("[calculateProjectedSavings] No such organization document!");
    };

    // Calculate the total projected savings
    let totalSavings = 0;

    // Iterate through the repeat days
    for (const day of repeat) {
      const hourlySavings = average_savings_data[day];

      if (!hourlySavings) {
        console.warn(`No data for day: ${day}`);
        continue;
      };

      // Determine off hours
      let offHours = [];
      if (onHour > offHour) {
        // Case (e.g., onHour: 22, offHour: 6)
        offHours = Array.from({ length: onHour - offHour }, (_, index) => offHour + index);
      } else {
        // Normal case (e.g., onHour: 6, offHour: 18)
        offHours.push(...Array.from({ length: onHour }, (_, i) => i));
        offHours.push(...Array.from({ length: 24 - offHour }, (_, i) => offHour + i));
      };

      // console.log("[CalculatedPotentialSavings]", offHours);

      // Add up the savings for the off hours
      for (const hour of offHours) {
        totalSavings += hourlySavings[hour % 24] || 0; // Handle edge cases with modulus
      };
    };

    totalSavings = (totalSavings / 1000) * rate;

    return totalSavings;
  } catch (error) {
    console.error("Error calculating projected savings:", error);
    throw error;
  };
};

export const calculateTotalProjectedSavings = async (schedules, setProjectedSavings, filterType) => {
  // check structure of input
  if (!Array.isArray(schedules) || schedules?.length === 0) {
    console.error("Invalid or empty schedules array");
    return;
  };

  // Filter schedules by the provided type ("New" or "Accepted")
  const filteredSchedules = schedules.filter(
    (schedule) => schedule.status === filterType
  );

  // sets savings to zero if no schedules fit filter
  if (filteredSchedules.length === 0) {
    console.warn(`No schedules found for type: ${filterType}`);
    setProjectedSavings(0);
    return;
  };

  // Use Promise.all to fetch savings concurrently for each schedule
  const totalSavingsArray = await Promise.all(
    filteredSchedules.map(async (schedule) => {
      const {
        outletsSelected,
        onHour,
        onAMPMDropdownValue,
        offHour,
        offAMPMDropdownValue,
        onMinute,
        offMinute,
        repeat
      } = schedule;

      if (outletsSelected?.length === 0) {
        return 0; // Skip schedules with no selected outlets
      };

      // Convert hours to 24-hour format
      let onHourHelp = Number(onHour);
      if (onAMPMDropdownValue === 'PM') {
        onHourHelp += 12;
      } else if (onHourHelp === 12) {
        onHourHelp = 0;
      };

      let offHourHelp = Number(offHour);
      if (offAMPMDropdownValue === 'PM' && offHourHelp !== 12) {
        offHourHelp += 12;
      } else if (offAMPMDropdownValue === 'AM' && offHourHelp === 12) {
        offHourHelp = 0;
      };

      // Calculate savings for all outlets in the schedule
      const savingsArray = await Promise.all(
        outletsSelected.map((outlet) =>
          calculateProjectedSavings(
            outlet, // Outlet ID
            onHourHelp,
            onMinute,
            offHourHelp,
            offMinute,
            repeat
          )
        )
      );

      // Sum up savings for this schedule
      return savingsArray.reduce((sum, savings) => sum + savings, 0);
    })
  );

  // Calculate total savings across all filtered schedules
  const totalSavings = totalSavingsArray.reduce((sum, scheduleSavings) => sum + scheduleSavings, 0);

  setProjectedSavings(totalSavings);
};