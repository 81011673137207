import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import CustomButton from '../../../components/customButton';
import TextField from '../../../components/textField';
import styles from "../styles";
import IconButton from "../../iconButton";
import { updateWifiInformation } from "../../../functions/organizations";

export default function WifiInformationForm({
    open,
    handleClose,
    name,
    orgID,
    setWifiSubmitted,
}) {
    const [SSID, setSSID] = useState('');
    const [password, setPassword] = useState('');
    const [confirmSSID, setConfirmSSID] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [SSIDError, setSSIDError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmSSIDError, setConfirmSSIDError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const handleSSIDChange = (value) => {
        if (value?.length > 0) {
            setSSID(value);
            if (confirmSSID?.length > 0) {
                if (value !== confirmSSID) {
                    setSSIDError(true);
                    setConfirmSSIDError(true);
                }else{
                    setSSIDError(false);
                    setConfirmSSIDError(false);
                };
            };
        } else {
            setSSIDError(true);
        };
    };
    const handlePasswordChange = (value) => {
        if (value?.length > 0) {
            setPassword(value);
            if (confirmPassword?.length > 0) {
                if (value !== confirmPassword) {
                    setPasswordError(true);
                    setConfirmPasswordError(true);
                } else {
                    setPasswordError(false);
                    setConfirmPasswordError(false);
                };
            };
        } else {
            setPasswordError(true);
        };
    };
    const handleConfirmSSIDChange = (value) => {
        if (value?.length > 0) {
            setConfirmSSID(value);
            if (value !== SSID) {
                setSSIDError(true);
                setConfirmSSIDError(true);
            } else {
                setSSIDError(false);
                setConfirmSSIDError(false);
            };
        } else {
            setSSIDError(true);
            setConfirmSSIDError(true);
        };
    };
    const handleConfirmPasswordChange = (value) => {
        if (value?.length > 0) {
            setConfirmPassword(value);
            if (value !== password) {
                setPasswordError(true);
                setConfirmPasswordError(true);
            } else {
                setPasswordError(false);
                setConfirmPasswordError(false);
            };
        } else {
            setPasswordError(true);
            setConfirmPasswordError(true);
        };
    };
    const handleSubmit = async () =>{
        try {
            await updateWifiInformation(orgID, SSID, password);
            setWifiSubmitted(true);
            handleClickClose();
        } catch (error) {
            console.error("Error submitting WiFi info:", error);
            alert('Error! WiFi information not submitted');
        };
    };
    const handleClickClose = () => {
        setSSID('');
        setConfirmSSID('');
        setPassword('');
        setConfirmPassword('');
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClickClose}>
            <Box sx={styles.container}>
                <div style={{ ...styles.innerContainer, justifyContent: 'flex-start' }}>
                    {/* header/close button */}
                    <div style={styles.headerContainer}>
                        <div
                            className="titleLarge"
                            style={styles.headerText}
                        >
                            WiFi Information for {name}
                        </div>
                        <IconButton
                            iconName='cancel-1'
                            tone="dark"
                            size={24}
                            onClick={handleClickClose}
                        />
                    </div>
                    <div style={{textAlign:'center', color:'red'}}>
                        This data is exclusively used for coding and installing your outlets<br />and will be immediately deleted upon successful installation.
                    </div>
                    {/* wifi info */}
                    <div style={{ alignSelf: 'center' }}>
                        <div style={{ height: '100px' }}>
                            <TextField
                                label="SSID"
                                placeholder="Enter SSID for your building's WiFi"
                                size="medium"
                                onChange={handleSSIDChange}
                                descriptionText={SSIDError ? 'SSIDs must match' : ''}
                                textError={SSIDError ? true : false}
                                background="light"
                            />
                        </div>
                        <div style={{ height: '100px' }}>
                            <TextField
                                label="Confirm SSID"
                                placeholder="Confirm SSID for your building's WiFi"
                                size="medium"
                                onChange={handleConfirmSSIDChange}
                                descriptionText={confirmSSIDError ? 'SSIDs must match' : ''}
                                textError={confirmSSIDError ? true : false}
                                background="light"
                            />
                        </div>
                        <div style={{ height: '100px' }}>
                            <TextField
                                label="Password"
                                placeholder="Enter password for your building's WiFi"
                                visibility={true}
                                size="medium"
                                onChange={handlePasswordChange}
                                descriptionText={passwordError ? 'Passwords must match' : ''}
                                textError={passwordError ? true : false}
                                background="light"
                            />
                        </div>
                        <div style={{ height: '100px' }}>
                            <TextField
                                label="Confirm Password"
                                placeholder="Confirm password for your building's WiFi"
                                visibility={true}
                                size="medium"
                                onChange={handleConfirmPasswordChange}
                                descriptionText={confirmPasswordError ? 'Passwords must match' : ''}
                                textError={confirmPasswordError ? true : false}
                                background="light"
                            />
                        </div>
                    </div>
                    {/* submit button */}
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: '10px',
                    }}>
                        <CustomButton
                            label="Submit WiFi Information"
                            filled={true}
                            custFontSize={16}
                            iconName={'arrow-right'}
                            size="medium"
                            onClick={handleSubmit}
                            disabled={
                                (SSIDError || confirmSSIDError || passwordError || confirmPasswordError) || (SSID === '' || confirmSSID === '' || password === '' || confirmPassword === '') ?
                                true : false
                            }
                        />
                    </div>
                </div>
            </Box>
        </Modal>
    );
};