import React, { useState, useEffect } from "react";
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { orgSelectPress } from "../../functions/organizations";
import DropDown from "../../components/dropDown"

//must detect in home that current user is memeber of ReVert Org
export default function OrganizationPicker({
  allOrgs,
  orgID,
  selectedOrgID,
  setSelectedOrgID,
  setTimezone,
  setRate,
  setSelectedApplianceType = null,
  label = false
}) {
  //useEffect to get all of the possible organizations
  //useState variable to hold the list of orgs
  //drop down box with each org as an option
  //sets state of OrgID to be sent into outletList with selected option
  const [orgEdit, setOrgEdit] = useState(false);
  //const [orgName, setOrgName] = useState("ReVert Technologies, Inc.");
  //should be org name not orgID
  const [pickedOrgID, setPickedOrgID] = useState(selectedOrgID
    //localStorage.getItem("selectedOrgID") || ""
  );
  const [pickedOrgName, setPickedOrgName] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!selectedOrgID && allOrgs.length > 1) {
      orgSelectPress(
        pickedOrgID,
        allOrgs[0].uid,
        setPickedOrgID,
        setOrgEdit,
        setSelectedOrgID,
        setTimezone,
        setRate
      );
      setPickedOrgName(Object.values(allOrgs).find(item => item.uid === allOrgs[0].uid).name);
    }else if(allOrgs.length > 1){
      setPickedOrgName(Object.values(allOrgs).find(item => item.uid === pickedOrgID).name);
    }
  }, [allOrgs]);

  const selectOrgPress = (orgName) => {
    //note: function that updates selected orgID
    setPickedOrgName(orgName)
    orgSelectPress(
      pickedOrgID,
      Object.values(allOrgs).find(item => item.name === orgName).uid,
      setPickedOrgID,
      setOrgEdit,
      setSelectedOrgID,
      setTimezone,
      setRate
    );
    if (setSelectedApplianceType) setSelectedApplianceType("");
  };

  // let parentOrgs = [];
  // let filteredOrgs = allOrgs;

  // allOrgs.map((org) => {
  //   if (org.child_orgs) {
  //     parentOrgs.push(org)
  //   };
  // });

  // parentOrgs.sort((a, b) => {
  //   if (a.name < b.name) {
  //     return -1;
  //   };
  //   if (a.name > b.name) {
  //     return 1;
  //   };
  // });

  const orgNames = Object.values(allOrgs).map(item => item.name);
  orgNames.sort((a, b) => {
    if (a < b) {
      return -1;
    };
    if (a > b) {
      return 1;
    };
  });

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: 'center',
    }}>
      <DropDown label={label && 'Organization'} list={orgNames} value={pickedOrgName} onSelect={selectOrgPress} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}/>
    </div>
  );
}