import React, { useEffect, useState } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { neutral400, primary500 } from "../../lib/rebrandColors/colors";
import MonthlyCalendar from "../monthlyCalendar";
import TimePicker from "../timePicker";
import EditIcon from "@mui/icons-material/Edit";
import DailyScheduleView from "../dailyScheduleView";
import WeeklyScheduleView from "../weeklyScheduleView";
import ScheduleForm from "../forms/scheduleForm";
import SpecialEventForm from "../forms/specialEventForm";
import { calculateTotalProjectedSavings } from "../../functions/savings";
import { black } from "../../lib/rebrandColors/colors";

export default function CalendarContainer({
  events,
  schedules,
  setSchedules,
  orgID,
  selectedOrgID,
  isOverviewAccount,
  timezone,
  orgName,
  child_orgs,
  orgData,
  loading,
  windowDimensions,
  outlets,
  refresh,
  setRefresh,
  subOrgDropdownValue,
  role,
}) {
  const [view, setView] = useState("week"); // Default to month view
  const [selectedDate, setSelectedDate] = useState(new Date()); // Store the date selected by TimePicker
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [clickedSchedule, setClickedSchedule] = useState();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [scheduleEdit, setScheduleEdit] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState();
  const [projectedSavings, setProjectedSavings] = useState(0);


  // Update date based on TimePicker selection
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Handle view change
  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  // Modal open/close handlers
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const scheduleClick = (schedule) => {
    setIsScheduleOpen(true);
    setScheduleEdit(true);
    setClickedSchedule(schedule);
  };
  const openScheduleModal = (edit) => {
    setIsScheduleOpen(true);
    setScheduleEdit(edit);
  };
  const closeScheduleModal = () => {
    setIsScheduleOpen(false);
    setClickedSchedule();
  };

  useEffect(() => {
    calculateTotalProjectedSavings(schedules, setProjectedSavings, 'Accepted');
  }, [schedules]);


  return (
    <Box
      sx={{
        width: "1136px", // Fixed width
        height: "792px", // Fixed height
        padding: "24px", // Padding inside the container for spacing
        backgroundColor: "#F9F9F9",
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden", // Prevent overflow outside container
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%", // Stretch header to full width of the container
        }}
      >
        {/* Tabs for View Selection */}
        <div style={{ width: '33%' }}>
          <Tabs
            value={view}
            onChange={handleViewChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: primary500, // Red underline for the selected tab
              },
              "& .MuiTab-root": {
                textTransform: "none",
                minWidth: "auto",
                padding: "0 16px",
                color: neutral400, // Default color for unselected tabs
                fontFamily: "IBM Plex Mono",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              },
              "& .MuiTab-root.Mui-selected": {
                color: primary500, // Red color for the selected tab text
              },
            }}
          >
            <Tab label="Month" value="month" />
            <Tab label="Week" value="week" />
            <Tab label="Day" value="day" />
          </Tabs>
        </div>

        <div style={{
          fontSize: '18px',
          color: black,
          // width: '33%'
        }}>
          Projected Total Weekly Savings:
          <span style={{ fontWeight: 'bold', }}> ${projectedSavings.toFixed(2)}</span>
        </div>
        {/* Conditional Button */}
        {view === "week" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mb: 2,
              width: '33%'
            }}
          >
            {/* <Button
              variant="outlined"
              onClick={() => openScheduleModal(true)}
              sx={{
                borderColor: "#E50914",
                color: "#E50914",
                fontWeight: "bold",
                textTransform: "none",
                padding: "8px 16px",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  borderColor: "#E50914",
                  backgroundColor: "transparent",
                },
                cursor: role === "view" ? "not-allowed" : "pointer",
              }}
              startIcon={<EditIcon />}
              disabled={role === "view" ? true : false}
            >
              Edit
            </Button> */}
            <Button
              variant="contained"
              onClick={() => openScheduleModal(false)}
              sx={{
                backgroundColor: primary500,
                color: "#FFFFFF",
                fontWeight: "bold",
                textTransform: "none",
                padding: "8px 16px",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#D32F2F",
                },
                cursor: role === "view" ? "not-allowed" : "pointer",
              }}
              disabled={role === "view" ? true : false}
            >
              + Add Schedule
            </Button>
          </Box>
        ) : (
          <div style={{ width: '33%', justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              variant="outlined"
              onClick={openModal} // Opens modal on click
              sx={{
                borderColor: "#E50914",
                color: "#E50914",
                fontWeight: "bold",
                textTransform: "none",
                padding: "8px 16px",
                borderRadius: "24px",
                "&:hover": {
                  borderColor: "#E50914",
                  backgroundColor: "transparent",
                },
                cursor: role === "view" ? "not-allowed" : "pointer",
              }}
              disabled={role === "view" ? true : false}
            >
              + Add special event
            </Button>
          </div>
        )}
      </Box>

      {/* Time Picker */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <TimePicker
          timeSpan={view.charAt(0).toUpperCase() + view.slice(1)}
          setTime={handleDateChange}
        />
      </Box>

      {/* Calendar Content */}
      {view === "month" && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            padding: "16px", // Space around the calendar inside the container
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            scrollbarWidth: "none",
            justifyContent: "center",
            // overflowX: "auto", // Horizontal scroll if needed for smaller screens
          }}
        >
          <MonthlyCalendar
            date={selectedDate}
            events={events}
            setIsEventEditModalOpen={setIsModalOpen}
            setSelectedEvent={setSelectedEvent}
          />
        </Box>
      )}
      {view === "week" &&
        (schedules?.length === 0 ? (
          <div
            className="headlineMedium"
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "50px",
              color: "#999999",
            }}
          >
            No schedules today
          </div>
        ) : (
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              justifyContent: "center",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <WeeklyScheduleView
              schedules={schedules}
              org={subOrgDropdownValue ? subOrgDropdownValue : "All"}
              outlets={outlets}
              orgData={orgData}
              scheduleClick={scheduleClick}
              setClickedSchedule={setClickedSchedule}
            />
          </Box>
        ))}
      {view === "day" && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <DailyScheduleView
            width={windowDimensions.width}
            height={"615px"}
            schedules={schedules}
            day={selectedDate}
            orgID={orgID}
            selectedOrgID={selectedOrgID}
            isOverviewAccount={isOverviewAccount}
            timezone={timezone}
            orgName={orgName}
            child_orgs={child_orgs}
            orgData={orgData}
            loading={loading}
            outlets={outlets}
            role={role}
            scheduleClick={scheduleClick}
            setClickedSchedule={setClickedSchedule}
          />
        </Box>
      )}

      {/* Special Event Form Modal */}
      <SpecialEventForm
        open={isModalOpen}
        handleClose={closeModal}
        orgData={orgData}
        schedules={schedules}
        refresh={refresh}
        setRefresh={setRefresh}
        isOverviewAccount={isOverviewAccount}
        child_org_data={orgData}
        outlets={outlets}
        event={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
      {/* Add Schedule Form Modal */}
      <ScheduleForm
        edit={scheduleEdit}
        open={isScheduleOpen}
        handleClose={closeScheduleModal}
        outlets={outlets}
        child_org_data={orgData}
        setSchedules={setSchedules}
        orgID={orgID}
        selectedOrgID={selectedOrgID}
        refresh={refresh}
        setRefresh={setRefresh}
        schedule={clickedSchedule}
        setClickedSchedule={setClickedSchedule}
        schedules={schedules}
      />
    </Box>
  );
}
