import React, { useState, useEffect } from "react";
import CustomIcon from "../icon";
import {
    black,
    elevationLight1,
    neutral300,
    neutral400,
    primary500,
    grey600,
    white,
} from "../../lib/rebrandColors/colors";
import {
    Dialog,
    DialogContent,
  } from "@mui/material";
import '../../App.css'; // Import your fonts
import TextButton from "../textButton";
import { CircularProgress } from "@mui/material";
import Dropdown from "../dropDown";
import { updateTasks } from "../../functions/tasks"
import CustomButton from "../customButton";
import IconButton from "../iconButton";


// const filteredTasks = [
//     {'name': 'Placeholder Task', 'description': 'This is for testing'},
//     {'name': 'Placeholder Task 2', 'description': 'This is for testing'},
//     {'name': 'Placeholder Task 3', 'description': 'This is for testing'}
// ]

export default function TaskList({
    loading,
    height,
    tasks,
    setTasksLoading,
}) {
    //const [filterLoading, setFilterLoading] = useState(true);
    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [filteredTasks, setFilteredTasks] = useState(tasks);
    const [task, setTask] = useState({status:'Incomplete'})
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const filterTasks = (tasks) => {
        // sort by date
        return tasks.sort((a, b) => {
          const dateA = a.dateCreated;
          const dateB = b.dateCreated;
            if (dateA < dateB) {
            return -1;
            };
            if (dateA > dateB) {
            return 1;
            };

          // names must be equal
          return 0;
        });
    };

    useEffect(() => {
        let sortedTasks = filterTasks(tasks)
        sortedTasks = sortedTasks.filter(task => task.status === "Incomplete");
        setFilteredTasks(sortedTasks)
      }, [tasks]);

    const handleTaskClick = (task) => {
        setTask(task);
        setAssignDialogOpen(true);
    };

    const handleTaskUpdate = (value) => {
        updateTasks(task.id, value)
        setTasksLoading(true)
        setAssignDialogOpen(false)
    };

    return (
        <div style={{
            margin: '.5%',
            minWidth: '500px',
            borderRadius: '24px',
            minHeight: '824px',
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '40px',
                marginTop: '40px',
                justifyContent: 'space-between',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <CustomIcon
                        name="task"
                        color={black}
                        size={30}
                    />
                    <div
                        className="bodyLarge"
                        style={{
                            marginLeft: '8px',
                        }}
                    >
                        Tasks
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: height, //? height : `${listHeight-100}px`,
                overflowY: 'scroll',
                alignItems: 'center',
                width:'100%'
            }}>
            {loading ?
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '50px'
                }}>
                    <CircularProgress />
                </div> :
                filteredTasks.length > 0 ?
                filteredTasks.map((task, i) => {
                return (
                        <div style={{
                            display: 'flex',
                            width: '480px',
                            minHeight: '88px',
                            backgroundColor: '#EEEEEE',
                            borderRadius: '16px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight:'5px',
                            marginTop:'16px',
                        }}>
                            <div style={{width:'90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display:'flex', flexDirection:'column'}}>  
                                    <div className="labelMedium"
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            color: grey600
                                        }}
                                    >  
                                    {task.name}
                                    </div>
                                    <div className="labelMedium"
                                        style={{
                                            fontSize: '12px',
                                            color: '#666666',
                                            marginTop:'10px'
                                        }}
                                    > 
                                        {task.description}
                                    </div>
                                </div>
                                <IconButton
                                    iconName='more-vertical-circle-1'
                                    tone='light'
                                    onClick={() => handleTaskClick(task)}
                                />
                            </div>
                        </div>
                    )})
                    :
                    <div
                        className="headlineMedium"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            marginTop: '50px',
                            color: '#999999'
                        }}
                    >
                        No tasks assigned
                    </div>
                }
            </div>
            <Dialog
                open={assignDialogOpen}
                onClose={() => setAssignDialogOpen(false)}

            >       
                <div style={{
                    marginTop:'20px',
                    marginLeft:'20px',
                    width: '92.5%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div
                    className="titleLarge"
                    style={{ color: '#1F1F1F', textAlign: "Left" }}
                    >
                        Mark task complete
                    </div>
                    <IconButton iconName='cancel-1' tone='dark' size={24} onClick={() => setAssignDialogOpen(false)} />
                </div>
                <DialogContent style={{ width: '500px', height: '100px', display:'flex', 'justifyContent':'center', alignItems:'center'}} >
                <div style={{height: '50px', width:'75%', display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>                
                    <CustomButton filled={true} label='Completed' onClick={() => handleTaskUpdate('Complete')}/>
                    <CustomButton label='Ignore' onClick={() => handleTaskUpdate('Dismiss')}/> 
                </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};