import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Box,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../../App.css'; // Import your fonts
import Dropdown from "../../dropDown";
import TextField from "../../textField";
import CustomButton from "../../customButton";
import IconButton from "../../iconButton";
import styles from '../styles';
import { handleSpecialEventSubmit, handleSpecialEventEditSubmit, handleSpecialEventDeleteSubmit } from "../../../functions/events";

export default function SpecialEventForm({
  open,
  handleClose,
  orgData,
  schedules,
  refresh,
  setRefresh,
  isOverviewAccount,
  child_org_data,
  organizationID,
  organizationName,
  outlets,
  event = null,
  setSelectedEvent,
}) {
  const [eventDate, setEventDate] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [subOrganization, setSubOrganization] = useState("");
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [eventName, setEventName] = useState('');
  const [eventDescription, setEventDescription] = useState("");
  const [action, setAction] = useState(false);
  const [childOrgs, setChildOrgs] = useState([]);
  const [iOrganizationDropdownOpen, setIOrganizationDropdownOpen] =
    useState(false);
  const [orgArray, setOrgArray] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);

  const [statusDropdownValue, setStatusDropdownValue] = useState('On');
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const statusDropdownClick = (item) => {
    setStatusDropdownValue(item);
  };
  const [statusDropdownArr, setStatusDropdownArr] = useState(['On', 'Off']);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    if (event !== null) {
      setEventDate(event.date);
      setStatusDropdownValue(event.action);
      setSelectedOutlets(outlets.filter((obj) => event.outlets.includes(obj.id)));
      setEventName(event.title);
    };
  }, [open]);

  const onSubmit = () => {
    // Transforming the array to just contain the outlet ids
    const newOutletsSelected = selectedOutlets.map(obj => obj.id);

    // if submitting a new event do this
    if(event == null){
      const eventData = {
        startDate: startDate,
        endDate: endDate,
        subOrganization: subOrganization,
        outlets: newOutletsSelected,
        name: eventName,
        action: statusDropdownValue,
      };
      handleSpecialEventSubmit(eventData, orgData, schedules, refresh, setRefresh);
      setStartDate();
      setEndDate();
      setEventName('');
      setSelectedOutlets([]);
      setSelectedOrgs([]);
      handleClose();
    }
    //if editing an existing event do this
    else{
      const eventData = {
        date: eventDate,
        name: eventName,
        action: statusDropdownValue,
        outlets: newOutletsSelected,
        id: event.id,
      };
      handleSpecialEventEditSubmit(eventData, orgData, schedules, refresh, setRefresh);
      setEventDate('');
      setEventName('');
      setSelectedOutlets([]);
      handleClose();
    }
  };

  const onDeleteSubmit = () => {
    const eventData = {
        id: event.id,
    };
    handleSpecialEventDeleteSubmit(eventData, schedules, refresh, setRefresh);
    setEventDate('');
    setEventName('');
    setSelectedOutlets([]);
    handleClose();
  };

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  
  const handleDeleteClick = () => {
      setShowConfirmPopup(true);
  };

  const handleConfirmDelete = () => {
      setShowConfirmPopup(false);
      onDeleteSubmit(); // Call the delete function
  };

  const handleCancelDelete = () => {
      setShowConfirmPopup(false);
  };

  const handleClickClose = () => {
    setStartDate();
    setEndDate();
    setEventName('');
    setSelectedOutlets([]);
    setSelectedOrgs([]);
    if(setSelectedEvent){
      setSelectedEvent();
    }
    handleClose();
  }

  return (
    <Modal open={open} onClose={handleClickClose}>
      <Box
        sx={styles.container}
      >
        <div style={styles.innerContainer}>
          <div style={styles.headerContainer}>
            <div
              className="titleLarge"
              style={styles.headerText}
            >
              {event === null ? 'Add Special Event' : 'Edit Event'}
            </div>
            <IconButton iconName='cancel-1' tone='dark' size={24} onClick={handleClickClose} />
          </div>
          <TextField
            label="Special Event Name *"
            placeholder="Enter event name"
            required
            value={eventName}
            onChange={setEventName}
            size="medium"
            background='light'
          />
          {/* {child_org_data.length > 1 && (
            <FormControl sx={{ width: "485px", marginTop: '10px' }}>
              <InputLabel
                id="org-label"
                sx={{
                  color: neutral800,
                  "&.Mui-focused": {
                    color: neutral800,
                  },
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Sub Organizations
              </InputLabel>
              <Select
                labelId="org-label"
                multiple
                value={selectedOrgs}
                onChange={(e) => {
                  setSelectedOrgs(e.target.value);
                }}
                label="Orgs"
                required
                renderValue={(selectedOrg) =>
                  selectedOrg.length ? selectedOrg.join(", ") : "Select Sub Organizations"
                }
                sx={{
                  backgroundColor: '#FAFAFA', //neutral700,
                  borderRadius: "34px",
                  marginTop: child_org_data && selectedOrgs.length > 0 ? "12px" : "0px",
                  border: '1px solid #999999',
                  color: '#999999',
                  "& .MuiSelect-icon": {
                    color: '#999999',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: '#999999',
                  },
                  "&.Mui-focused": {
                    marginTop: child_org_data ? "12px" : "0px"
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: neutral800,
                      "& .MuiMenuItem-root": {
                        color: '#999999',
                        "&:hover": {
                          backgroundColor: primary500,
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: primary500,
                        "&:hover": {
                          backgroundColor: primary500,
                        },
                      },
                    },
                  },
                }}
              >
                {child_org_data.map((org) => {
                  return (
                    <MenuItem key={org.uid} value={org.uid}>
                      {org.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )} */}
          {event === null && 
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '485px',
            justifyContent: 'space-evenly'
          }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="End date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </LocalizationProvider>
          </div>
          }
          <FormControl sx={{ width: "485px", marginTop: '10px' }}>
            <Select
                multiple
                value={selectedOutlets}
                onChange={(e) => setSelectedOutlets(e.target.value)}
                required
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((outlet) => (
                        <Chip key={outlet.id} label={outlet.name} sx={{ margin: '2px' }} />
                      ))}
                    </Box>
                  )}
            >
                {outlets.map((outlet) => (
                    <MenuItem key={outlet.id} value={outlet}>
                        {/* {outlet.name} */}
                        <Checkbox checked={selectedOutlets.includes(outlet)} />
                        <ListItemText primary={outlet.name} />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>Select outlets for this event</FormHelperText>
        </FormControl>

          <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <div
              className="bodyMedium"
              style={{ marginBottom: '10px' }}
            >
              Appliances will be kept {statusDropdownValue === 'On' ? "ON" : "OFF"} during this event
            </div>
            <Dropdown
              list={statusDropdownArr}
              value={statusDropdownValue}
              onSelect={statusDropdownClick}
              setIsDropdownOpen={setStatusDropdownOpen}
              isDropdownOpen={statusDropdownOpen}
              background="light"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              filled={true}
              label={event === null ? "Create event" : "Update event"}
              onClick={onSubmit}
              size="medium"
              disabled={(
                eventName !== '' &&
                typeof startDate === 'object' &&
                typeof endDate === 'object' &&
                selectedOutlets.length !== 0
              ) ? false : true}
            />
          </div>
          {event !== null && (
            <>
            <CustomButton
                filled={false}
                label="Delete Event"
                onClick={handleDeleteClick}
                size="medium"
            />
            {showConfirmPopup && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                            textAlign: 'center',
                            width: '400px'
                        }}
                    >
                        <div
                            className="labelLarge"
                        >
                            Are you sure you want to delete this event?
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginTop: '20px'
                        }}>
                            <CustomButton
                                filled={true}
                                label="Yes, Delete"
                                onClick={handleConfirmDelete}
                                size="small"
                            />
                            <CustomButton
                                filled={false}
                                label="Cancel"
                                onClick={handleCancelDelete}
                                size="small"
                            />
                        </div>
                    </div>
                </div>
            )}
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};