import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ApplianceDetailsCard from "../../components/applianceDetailsCard";
import CustomButton from "../../components/customButton"; 
import { primary500, white } from "../../lib/rebrandColors/colors";
import { updateOutletDetails } from "../../functions/outlets";

const ApplianceEditScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const outlet = location.state?.outlet;
  const prodTypes = location.state?.prodTypes;
  const orgData = location.state?.orgData;
  const wattHours = location.state?.wattHours;
  const averageUsage = location.state?.averageUsage;
  const savings = location.state?.savings;
  const path = location.pathname;


  const [editedOutlet, setEditedOutlet] = useState({ ...outlet });
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (field, value) => {
    setEditedOutlet((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCancel = () => {
    navigate("/appliances/details", { state: { outlet, prodTypes, orgData, wattHours, averageUsage, savings, path } });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      console.log("[Appliance Edit Details] Updating Outlet Data:", editedOutlet);

      // Call the updateOutlet function
      await updateOutletDetails(editedOutlet);

      console.log("[Appliance Edit Details] Outlet updated successfully");
      navigate("/appliances/details", { state: { outlet: editedOutlet, prodTypes, orgData,  wattHours, averageUsage, savings  } });
    } catch (error) {
      console.error("[Appliance Edit Details] Failed to update outlet:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#1F1F1F",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        paddingTop: "25px",
      }}
    >
      {/* Page Header */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <Typography
          sx={{
            color: white,
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Edit Appliance
        </Typography>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", gap: "16px" }}>
          <CustomButton
            label="Cancel"
            filled={false}
            size="small"
            onClick={handleCancel}
            disabled={loading}
            colored={true}
          />
          <CustomButton
            label={loading ? "Saving..." : "Save"}
            filled={true}
            size="small"
            onClick={handleSave}
            disabled={loading}
            colored={true}
          />
        </Box>
      </Box>

      {/* ApplianceDetailsCard in Editing Mode */}
      <ApplianceDetailsCard
        outlet={editedOutlet}
        editing={true}
        onFieldChange={handleFieldChange}
        orgData={orgData}
        prodTypes={prodTypes}
        wattHours={wattHours}
        averageUsage={averageUsage}
        savings={savings}
      />
    </div>
  );
};

export default ApplianceEditScreen;