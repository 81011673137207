import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import db from "../firebase";
import { getAuth, deleteUser } from "@firebase/auth";
import moment from "moment";
import { uid } from "../firebase";
import { updateUserRole } from "./rolesManagement";

export const fetchOrgID = async () => {
  let orgID_helper = null;
  let timezone_helper = "UTC";
  let rate_helper = "0.16";
  let orgName_helper = '';
  let child_org_helper = [];
  try {
    let querySnapshot = await getDocs(collection(db, "organizations"));
    querySnapshot.forEach((doc) => {
      if (doc.data().users.includes(uid)) {
        // console.log('doc', doc.data().child_orgs);
        orgID_helper = doc.id;
        timezone_helper = doc.data().timezone;
        rate_helper = doc.data().rate;
        orgName_helper = doc.data().name;
        if (doc.data()?.child_orgs) {
          child_org_helper = doc.data().child_orgs;
        };
        return {
          orgID: orgID_helper,
          timezone: timezone_helper,
          rate: rate_helper,
          orgName: orgName_helper,
          child_orgs: child_org_helper,
        };
      };
    });
  } catch (error) {
    console.error("Error fetching document:", error);
  };
  return {
    orgID: orgID_helper,
    timezone: timezone_helper,
    rate: rate_helper,
    orgName: orgName_helper,
    child_orgs: child_org_helper,
  };
};

//note: might not need this and do everything inside fetchOrgID function
export const fetchOrganizationInfo = (setAllOrgs) => {
  const orgList = [];
  const getOrganizationInfo = async () => {
    let organizationInfo;
    //note: leaving because of the indexing thing
    organizationInfo = await getDocs(collection(db, "organizations"));

    const orgIDQ = query(collection(db, "organizations"));
    const orgIDsSnapshot = await getDocs(orgIDQ);
    orgIDsSnapshot.forEach((doc) => {
      const docData = doc._document.data.value.mapValue.fields;
      // console.log(docData, docData.name?.stringValue);
      orgList.push(docData);
    });
    setAllOrgs(orgList);
  };
  getOrganizationInfo();
};

export const fetchOrganizationIDs = (setAllOrgs) => {
  const getOrganizationIDs = async () => {
    const orgList = [];
    const orgDocs = await getDocs(collection(db, "organizations"));

    orgDocs.forEach((doc) => {
      const docData = doc._document.data.value.mapValue.fields;
      if ("child_orgs" in docData) {
        orgList.push({
          uid: docData["uid"].stringValue,
          name: docData["name"].stringValue,
          child_orgs: docData["child_orgs"].arrayValue.values.map(
            (obj) => Object.values(obj)[0]
          ),
        });
      } else {
        orgList.push({
          uid: docData["uid"].stringValue,
          name: docData["name"].stringValue,
        });
      }
    });

    setAllOrgs(orgList);
    return orgList;
  };
  return getOrganizationIDs();
};

export const fetchSpecificTeamOrganizationIDs = (orgID, setAllOrgs) => {
  const getOrganizationIDs = async () => {
    const orgList = [];
    let childOrgs = [];
    const orgDoc = await getDoc(doc(db, "organizations", orgID));
    if (orgDoc.exists()) {
      const orgData = orgDoc.data();
      if ("child_orgs" in orgData) {
        childOrgs = orgData["child_orgs"];
        orgList.push({
          uid: orgData["uid"],
          name: orgData["name"],
          child_orgs: orgData["child_orgs"],
        });

        const q = query(
          collection(db, "organizations"),
          where("uid", "in", childOrgs)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          orgList.push({ uid: doc.data()["uid"], name: doc.data()["name"] });
        });
      }
    }
    setAllOrgs(orgList);
    return orgList;
  };
  return getOrganizationIDs();
};

export const orgSelectPress = (
  oldPickedOrg,
  newPickedOrg,
  setPickedOrg,
  setOrgEdit,
  setSelectedOrgID,
  setTimezone,
  setRate,
) => {
  const previousOrg = oldPickedOrg;
  const displayOrg = newPickedOrg;//event.target.value;
  setPickedOrg(displayOrg);
  setSelectedOrgID(displayOrg);
  localStorage.setItem("selectedOrgID", displayOrg);
  //setOrgName(orgName);
  const getSelectedTimezone = async () => {
    const orgTimezoneQ = query(
      collection(db, "organizations"),
      where("uid", "==", displayOrg)
    );
    const orgTimezoneSnapshot = await getDocs(orgTimezoneQ);
    orgTimezoneSnapshot.forEach((doc) => {
      let orgTimezone = doc.data().timezone;
      let orgRate = doc.data().rate;
      localStorage.setItem("timezone", orgTimezone);
      localStorage.setItem("rate", orgRate);
      setTimezone(orgTimezone);
      setRate(orgRate);
    });
  };
  getSelectedTimezone();
  setOrgEdit(false);
};

export const fetchOrganizationInfoFromID = async (orgID) => {
  const orgRef = doc(db, "organizations", orgID);
  const orgDoc = await getDoc(orgRef);
  if (orgDoc.exists()) {
    // console.log("Document data:", orgDoc.data());
    return orgDoc.data();
  } else {
    console.log("No such document!");
    return null;
  };
};

export const fetchProductTypesFromOrganization = async (
  orgID,
  setProdTypes
) => {
  try {
    const outletsRef = collection(db, `organizations/${orgID}/Outlets`);
    const querySnapshot = await getDocs(outletsRef);
    const productTypes = new Set();

    if (querySnapshot.empty) {
      console.log("There are no outlets.");
      setProdTypes(["All Outlets"]);
      return;
    }

    querySnapshot.forEach((doc) => {
      const outletData = doc.data();
      if (outletData.productType) {
        productTypes.add(outletData.productType);
      } else {
        productTypes.add("N/A");
      }
    });

    setProdTypes(Array.from(productTypes));
  } catch (error) {
    console.error("Error retrieving documents: ", error);
    setProdTypes(["All Outlets"]);
  }
};

export const fetchIsOverviewAccount = (orgID, setIsOverviewAccount) => {
  const getOverviewAccount = async () => {
    const orgRef = doc(db, "organizations", orgID);
    const orgDoc = await getDoc(orgRef);
    if (orgDoc.exists()) {
      const orgData = orgDoc.data();
      if ("child_orgs" in orgData) {
        if (Array.isArray(orgData.child_orgs) &&
          orgData.child_orgs.length > 0) {
          setIsOverviewAccount(true);
          localStorage.setItem("isOverviewAccount", true);
          return true;
        } else {
          localStorage.setItem("isOverviewAccount", false);
          return false;
        }
      } else {
        localStorage.setItem("isOverviewAccount", false);
        return false;
      }
    } else {
      console.log("No such document!");
      localStorage.setItem("isOverviewAccount", false);
      return false;
    }
  };
  return getOverviewAccount();
};

export const registerOrganization = async (organization) => {
  try {
    // Format the join date
    const formattedJoinDate = moment().format("YYYY-MM-DD");
    organization.joinDate = formattedJoinDate;

    const orgDocRef = doc(collection(db, "organizations"));
    organization.uid = orgDocRef.id;
    const zipcode_to_timezone = require("zipcode-to-timezone");
    const timezone = zipcode_to_timezone.lookup(organization.zipcode);

    // Set the document in Firestore with the organization data
    await setDoc(orgDocRef, {
      name: organization.name,
      rate: organization.rate,
      timezone: timezone,
      zipcode: organization.zipcode,
      address: organization.address,
      users: organization.users,
      joinDate: organization.joinDate,
      uid: organization.uid,
      status: organization.status,
      billUpload: false,
    });
    await setUsersOrgID(organization.uid, organization.users);
    // console.log(
    //   "Organization successfully registered with ID:",
    //   organization.uid
    // );
    return organization.uid;
  } catch (error) {
    console.error("Error registering organization:", error);
    throw new Error("Failed to register organization.");
  };
};

const setUsersOrgID = async (orgID, users) => {
  try {
    for (const uid of users) {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, {
        orgID: orgID,
      });

      // console.log(`User ${uid} successfully updated with orgID: ${orgID}`);
    }
  } catch (error) {
    console.error("Error updating user documents with orgID:", error);
    throw new Error("Failed to update user documents with orgID.");
  }
};

export const updateOrganizationRate = async (orgID, newRate) => {
  try {
    const organizationDocRef = doc(db, "organizations", orgID);
    await updateDoc(organizationDocRef, { rate: newRate });
  } catch (error) {
    throw new Error("Failed to update rate: " + error.message);
  }
};

export const updateOrganizationStatus = async (orgID, newStatus) => {
  const orgRef = doc(db, "organizations", orgID);

  try {
    // Update organization status
    await updateDoc(orgRef, { status: newStatus });
    // console.log(`Organization ${orgID} status updated to ${newStatus}`);

    // Fetch all users of the organization
    const organizationDoc = await getDoc(orgRef);
    if (organizationDoc.exists()) {
      const organizationData = organizationDoc.data();
      const userUIDs = organizationData.users;

      // Update role of all users to "admin"
      const updatePromises = userUIDs.map(async (userId) => {
        await updateUserRole(userId, "admin");
        // console.log(`Updated user ${userId} role to admin`);
      });

      // Await all role updates to complete
      await Promise.all(updatePromises);
    } else {
      console.log("No such organization exists!");
    }
  } catch (error) {
    throw new Error(
      "Failed to update organization status and user roles: " + error.message
    );
  }
};

export const fetchAllOrganizationsInfo = async () => {
  try {
    const organizationsCollection = collection(db, "organizations");
    const querySnapshot = await getDocs(organizationsCollection);

    const organizations = querySnapshot.docs.map((docSnapshot) => {
      return {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      };
    });

    return organizations;
  } catch (error) {
    console.error("Error fetching organizations:", error);
    return [];
  }
};

export const updateOrganizationBill = async (orgID, setBillUploaded) => {
  try {
    const organizationDocRef = doc(db, 'organizations', orgID);
    await updateDoc(organizationDocRef, { billUpload: true });
    setBillUploaded(true);
  } catch (error) {
    throw new Error('Failed to update bill status: ' + error.message);
  };
};

export const fetchAllSuborganizations = async (orgID) => {
  try {
    // Step 1: Fetch the organization document
    const orgDocRef = doc(db, "organizations", orgID);
    const orgDocSnapshot = await getDoc(orgDocRef);

    if (!orgDocSnapshot.exists()) {
      throw new Error(`Organization with ID ${orgID} does not exist`);
    }

    // Step 2: Extract child_orgs from the organization document
    const orgData = orgDocSnapshot.data();
    const childOrgIDs = orgData.child_orgs || [];

    if (childOrgIDs.length === 0) {
      return []; // No child organizations
    }

    // Step 3: Fetch data for each child organization
    const childOrgPromises = childOrgIDs.map(async (childOrgID) => {
      const childOrgRef = doc(db, "organizations", childOrgID);
      const childOrgSnapshot = await getDoc(childOrgRef);

      if (childOrgSnapshot.exists()) {
        return { id: childOrgID, ...childOrgSnapshot.data() };
      } else {
        console.warn(`Child organization with ID ${childOrgID} not found`);
        return null;
      }
    });

    // Step 4: Resolve all promises and filter out null values
    const childOrgs = (await Promise.all(childOrgPromises)).filter(Boolean);

    // Step 5: Return the list of all child organizations
    return childOrgs;
  } catch (error) {
    console.error("Error fetching suborganizations:", error);
    throw error;
  }
};

export async function returnOutletsToInventory(
  orgID,
) {
  try {
      const q = query(collection(db, 'outlets'), where('orgID', '==', orgID));
      const querySnapshot = await getDocs(q);
      for (const docSnapshot of querySnapshot.docs) {
          const outletData = docSnapshot.data();
          // re-add outlet to inventory
          await addDoc(collection(db, 'newDevices'), {
              Location: 'TechPlace',
              device: outletData.device
          });
          // delete outlet doc
          await deleteDoc(doc(db, 'outlets', docSnapshot.id));
      };
      console.log('outlet return and delete complete');
  } catch (error) {
      console.error('error moving outlets: ', error);
  };
};

export async function deleteOrgData(orgID,) {
  //const auth = getAuth();
  try {
      // queries
      const organizationsQ = query(collection(db, 'organizations'), where('uid', '==', orgID));
      const tasksQ = query(collection(db, 'tasks'), where('orgID', '==', orgID));
      const schedulesQ = query(collection(db, 'schedules'), where('orgID', '==', orgID));
      const savingsQ = query(collection(db, 'savings'), where('orgID', '==', orgID));
      const notificationsQ = query(collection(db, 'notifications'), where('orgID', '==', orgID));
      const hourlyWattHoursQ = query(collection(db, 'hourlyWattHours'), where('orgID', '==', orgID));
      const eventsQ = query(collection(db, 'events'), where('organizationID', '==', orgID));
      const averageUsageQ = query(collection(db, 'averageUsage'), where('orgID', '==', orgID));
      const usersQ = query(collection(db, 'users'), where('orgID', '==', orgID));
      // snapshots
      const organizationQSnapshot = await getDocs(organizationsQ);
      const tasksQSnapshot = await getDocs(tasksQ);
      const schedulesQSnapshot = await getDocs(schedulesQ);
      const savingsQSnapshot = await getDocs(savingsQ);
      const notificationsQSnapshot = await getDocs(notificationsQ);
      const hourlyWattHoursQSnapshot = await getDocs(hourlyWattHoursQ);
      const eventsQSnapshot = await getDocs(eventsQ);
      const averageUsageQSnapshot = await getDocs(averageUsageQ);
      const usersQSnapshot = await getDocs(usersQ);
      let orgName = "";
      // delete loops
      // delete org document
      for (const docSnapshot of organizationQSnapshot.docs) {
          const data = docSnapshot.data();
          orgName = data.name;
          // delete doc
          await deleteDoc(doc(db, 'organizations', docSnapshot.id));
      };
      // delete task documents
      for (const docSnapshot of tasksQSnapshot.docs) {
          // delete doc
          await deleteDoc(doc(db, 'tasks', docSnapshot.id));
      };
      // delete schedule documents
      for (const docSnapshot of schedulesQSnapshot.docs) {
          // delete doc
          await deleteDoc(doc(db, 'schedules', docSnapshot.id));
      };

      // Keep savings for now 
      // for (const docSnapshot of savingsQSnapshot.docs) {
      //     const data = docSnapshot.data();
      //     // delete doc
      //     await deleteDoc(doc(db, 'savings', docSnapshot.id));
      // };

      // delete notifications documents
      for (const docSnapshot of notificationsQSnapshot.docs) {
          // delete doc
          await deleteDoc(doc(db, 'notifications', docSnapshot.id));
      };

      // delete hourlyWattHours documents
      for (const docSnapshot of hourlyWattHoursQSnapshot.docs) {
          // delete doc
          await deleteDoc(doc(db, 'hourlyWattHours', docSnapshot.id));
      };

      // delete events documents
      for (const docSnapshot of eventsQSnapshot.docs) {
          // delete doc
          await deleteDoc(doc(db, 'events', docSnapshot.id));
      };

      // delete averageUsage documents
      for (const docSnapshot of averageUsageQSnapshot.docs) {
          // delete doc
          await deleteDoc(doc(db, 'averageUsage', docSnapshot.id));
      };

      // delete users documents
      for (const docSnapshot of usersQSnapshot.docs) {
          // const uid = data.uid
          // // doesn't seem to work remove user from auth first
          // try {
          //     const user = await auth.getUser(uid);
          //     await deleteUser(user);
          //     console.log(`Deleted Auth user with UID: ${uid}`);
          // } catch (error) {
          //     console.error(`Error deleting Auth user ${uid}:`, error);
          // };
          // delete doc
          await deleteDoc(doc(db, 'users', docSnapshot.id));
      };
      console.log('Org Data for', orgID, 'deleted successfully');
      alert('Organization data deleted successfully!');
  } catch (error) {
      console.error('error deleting data: ', error);
      alert('Error! Some organization data may not have been fully deleted.');
  }
};

export async function updateWifiInformation(orgID, ssid, password){
    try {
      // Reference to the document in the organizations collection
      const organizationDocRef = doc(db, 'organizations', orgID);

      // Map for wifiInformation
      const wifiInformation = {
        ssid,
        password,
      };

      // Update the document with the wifiInformation map
      await updateDoc(organizationDocRef, { wifiInformation });
    } catch (error) {
      console.error("Error updating wifi information:", error);
    };
};