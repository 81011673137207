import React, { useState } from "react";
import {
  Button,
  //TextField,
  Alert,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { registerNewUser, addUserToOrganization } from "../../functions/users";
import { fetchOrganizationInfoFromID } from "../../functions/organizations";
import {
  getAuth,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  RecaptchaVerifier,
  sendEmailVerification,
  PhoneAuthProvider,
  linkWithCredential,
  signInWithCustomToken,
  signOut
} from "firebase/auth";
import CustomLogo from "../../components/logo";
import { useNavigate } from "react-router-dom";
import { blue, green } from "../../lib/colors";
import { white } from "../../lib/rebrandColors/colors";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import TextField from "../../components/textField";
import CustomButton from "../../components/customButton";

const RegisterScreen = () => {
  const location = useLocation();
  const { orgID, setSuppressNavigation } = location.state || {};
  const [orgId, setOrgId] = useState(orgID || "");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [orgFound, setOrgFound] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailConfirmError, setEmailConfirmError] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  // const [recaptchaVerified, setRecaptchaVerified] = useState(false); 
  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isValidPassword = (password) => {
    if (password !== "") {
      return (
        password.length >= 8 &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /\d/.test(password)
      );
    } else {
      return true;
    };
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    };

    // Check if the phone number is at least 10 digits
    if (value.length < 10) {
      setPhoneError("Phone number must be at least 10 digits.");
    } else {
      setPhoneError("");
    };
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleOrgSearch = async () => {
    try {
      const orgDoc = await fetchOrganizationInfoFromID(orgId);
      if (!orgDoc) {
        setAlert({
          show: true,
          message: "Organization not found.",
          severity: "error",
        });
        setOrgFound(false);
      } else {
        setAlert({
          show: true,
          message: `Organization found: ${orgDoc.name}`,
          severity: "success",
        });
        setOrgFound(true);
      };
    } catch (error) {
      setAlert({
        show: true,
        message: "Error searching organization.",
        severity: "error",
      });
      console.error(error);
    };
  };

  const handleUsersCreation = async () => {
    const auth = getAuth();
    const newAuth = getAuth();
    let users = [];
    
    // setSuppressNavigation(true);
    // Determine the role based on the referring page
    const role = "member";
    try {
      const idToken = await auth.currentUser.getIdToken(/* forceRefresh= */ true);
      console.log('token issue?', idToken);
      
      const userCredential = await createUserWithEmailAndPassword(
        newAuth,
        email,
        password
      );
      const user = userCredential.user;
      await registerNewUser(user, orgId, role); // Pass the determined role ("pending" or "admin")
      await addUserToOrganization(user.uid, orgId);
      await signOut(newAuth);
      // setSuppressNavigation(false);
      console.log('token same?', idToken);
      await signInWithCustomToken(auth, idToken);
    } catch (error) {
      console.error(`Error creating user with email ${email}:`, error);
      setAlert({
        show: true,
        message: `Error creating user with email ${email}: ${error.message}`,
        severity: "error",
      });
      return false;
    }finally{
      // setSuppressNavigation(false);
    };
    return users;
  };

  const handleTextChange = (value) => {
    setOrgId(value)
  };

  const handleEmailChange = (value) => {
    // const value = e.target.value;

    // Basic email regex pattern:
    // - Starts with one or more characters (letters, numbers, dots, underscores, or hyphens)
    // - Must contain the @ symbol
    // - Domain name part with one or more characters
    // - Dot (.) followed by a valid top-level domain (2-6 characters)
    const isValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/.test(value);

    if (isValid) {
      setEmail(value);
      setEmailError(false);
    } else {
      setEmailError(true);
    };
  };
  const handlePasswordChange = (value) => {
    // const value = e.target.value;

    // Regex checks for:
    // - Minimum 8 characters
    // - At least one uppercase letter
    // - At least one lowercase letter
    // - At least one digit
    // - At least one special character
    const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value);

    if (isValid) {
      setPassword(value);
      setPasswordError(false);
    } else {
      setPasswordError(true);
    };
  };
  const handleConfirmPasswordChange = (value) => {
    // const value = e.target.value;
    const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value);
    const passwordValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(password);

    if (isValid && passwordValid) {
      setConfirmPassword(value);
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    };
  };
  const handleConfirmEmail = async () => {
    try {
      const auth = getAuth();
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.length === 0) {
        setEmailConfirmError(false);
        return true; // Email is valid and not in use
      } else {
        setEmailConfirmError(true);
        setEmailError(true);
        return false; // Email is already in use
      };
    } catch (error) {
      console.error("Error confirming email:", error);
      setEmailConfirmError(true);
      return false;
    };
  };
  const handleSubmit = async () => {
    // e.preventDefault();

    // Validate before submission
    const emailIsValid = await handleConfirmEmail();
    if (!emailIsValid) {
      //setProcessing(false); // Stop processing if email is invalid
      return; // Exit early if email validation fails
    };

    try {
      let users = await handleUsersCreation();
      // Show success alert
      setAlert({
        show: true,
        message: "User registered successfully!",
        severity: "success",
      });
      setTimeout(() => {
        navigate("/account",)
      }, 1500);
    } catch (error) {
      console.error("Error registering new member:", error);
      setAlert({
        show: true,
        message: `Error registering new member: ${error.message}`,
        severity: "error",
      });
    };
  };

  const handleLogoClick = () => {
    window.location.href = 'https://reverttechnologies.com/';
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      backgroundColor: '#1F1F1F',
      height: 'auto',
      minHeight: '100vh',
      minWidth: '1200px',
    }}>
      <div
        style={{
          display: "flex",
          width: '25%',
          minWidth: '250px',
        }}
      >
        <div
          style={{
            marginTop: '80px',//windowDimensions.height < 415 ? '30px' : '8vh',
            marginLeft: '140px',
            cursor: 'pointer',
          }}
          onClick={handleLogoClick}
        >
          <CustomLogo
            name="horizontal-negative"
          />
        </div>
      </div>
      <Box
        sx={{
          width: "60%",
          //justifyContent: 'center',
          display: "flex",
          flexDirection: "column",
          //alignItems: "center",
          maxWidth: "500px",
        }}
      >
        <div
          className="displayLarge"
          style={{
            color: white,
            fontWeight: 700,
            fontSize: "48px",
            textAlign: "left",
            alignSelf: 'start',
            marginBottom: '40px',
            marginTop: '140px'
          }}
        >
          Register member
        </div>
        <TextField
          placeholder={"Organization ID"}
          size={"medium"}
          onChange={handleTextChange}
          label="Organization ID"
          incomingValue={orgId}
        />
        {/* <TextField
          label="Organization ID"
          value={orgId}
          onChange={(e) => setOrgId(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ fontFamily: "Manrope", fontWeight: "250" }}
          InputLabelProps={{
            style: {
              fontFamily: "Manrope",
              fontWeight: 250,
            },
          }}
          disabled={orgFound}
        /> */}
        <CustomButton
          size={"large"}
          custFontSize={16}
          filled={true}
          disabled={orgFound}
          label='Search Organization'
          iconName={'search-1'}
          iconPosition="right"
          onClick={handleOrgSearch}
        />

        {alert.show && (
          <Alert severity={alert.severity} sx={{ mt: 2, mb: 2 }}>
            {alert.message}
          </Alert>
        )}

        {orgFound && (
          <>
            <TextField
              label="Email"
              placeholder="help@reverttechnologies.com"
              size="medium"
              onChange={handleEmailChange}
              descriptionText={
                emailError
                  ? emailConfirmError ? 'Email already in use'
                    : 'Please enter a valid email'
                  : ''
              }
              textError={emailError || emailConfirmError ? true : false}
            />
            <TextField
              label="Password"
              placeholder="password"
              visibility={true}
              size="medium"
              onChange={handlePasswordChange}
              descriptionText={passwordError ? 'Passwords require upper and lower case letters, a number, and a special character' : ''}
              textError={passwordError ? true : false}
            />
            <div style={{
              marginBottom: '30px'
            }}>
              <TextField
                label="Confirm Password"
                placeholder="password"
                visibility={true}
                size="medium"
                onChange={handleConfirmPasswordChange}
                descriptionText={confirmPasswordError ? 'Passwords must match' : ''}
                textError={confirmPasswordError ? true : false}
              />
            </div>
            {/* <TextField
              label="Email"
              placeholder={"Email"}
              size={"medium"}
              value={email}
              onChange={handleEmailChange}
              disabled={isOtpSent}
              error={!emailValid && email.length > 0}
              helperText={
                !emailValid && email.length > 0
                  ? "Please enter a valid email address"
                  : ""
              }
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              required
              disabled={isOtpSent}
              sx={{ fontFamily: "Manrope", fontWeight: "250" }}
              InputLabelProps={{
                style: {
                  fontFamily: "Manrope",
                  fontWeight: 250,
                },
              }}
              error={!isValidPassword(password)}
              helperText={
                !isValidPassword(password)
                  ? "Password must be at least 8 characters long and include a number, a lowercase and an uppercase letter."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: "flex", width: "100%", gap: 1, marginY: 2 }}>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  displayEmpty
                  disabled={isOtpSent}
                >
                  <MenuItem value="+54">+54 (Argentina)</MenuItem>
                  <MenuItem value="+61">+61 (Australia)</MenuItem>
                  <MenuItem value="+32">+32 (Belgium)</MenuItem>
                  <MenuItem value="+55">+55 (Brazil)</MenuItem>
                  <MenuItem value="+1">+1 (Canada)</MenuItem>
                  <MenuItem value="+56">+56 (Chile)</MenuItem>
                  <MenuItem value="+86">+86 (China)</MenuItem>
                  <MenuItem value="+57">+57 (Colombia)</MenuItem>
                  <MenuItem value="+20">+20 (Egypt)</MenuItem>
                  <MenuItem value="+593">+593 (Ecuador)</MenuItem>
                  <MenuItem value="+372">+372 (Estonia)</MenuItem>
                  <MenuItem value="+358">+358 (Finland)</MenuItem>
                  <MenuItem value="+33">+33 (France)</MenuItem>
                  <MenuItem value="+49">+49 (Germany)</MenuItem>
                  <MenuItem value="+233">+233 (Ghana)</MenuItem>
                  <MenuItem value="+30">+30 (Greece)</MenuItem>
                  <MenuItem value="+502">+502 (Guatemala)</MenuItem>
                  <MenuItem value="+91">+91 (India)</MenuItem>
                  <MenuItem value="+62">+62 (Indonesia)</MenuItem>
                  <MenuItem value="+353">+353 (Ireland)</MenuItem>
                  <MenuItem value="+972">+972 (Israel)</MenuItem>
                  <MenuItem value="+39">+39 (Italy)</MenuItem>
                  <MenuItem value="+81">+81 (Japan)</MenuItem>
                  <MenuItem value="+60">+60 (Malaysia)</MenuItem>
                  <MenuItem value="+52">+52 (Mexico)</MenuItem>
                  <MenuItem value="+234">+234 (Nigeria)</MenuItem>
                  <MenuItem value="+47">+47 (Norway)</MenuItem>
                  <MenuItem value="+92">+92 (Pakistan)</MenuItem>
                  <MenuItem value="+51">+51 (Peru)</MenuItem>
                  <MenuItem value="+63">+63 (Philippines)</MenuItem>
                  <MenuItem value="+48">+48 (Poland)</MenuItem>
                  <MenuItem value="+351">+351 (Portugal)</MenuItem>
                  <MenuItem value="+7">+7 (Russia)</MenuItem>
                  <MenuItem value="+65">+65 (Singapore)</MenuItem>
                  <MenuItem value="+27">+27 (South Africa)</MenuItem>
                  <MenuItem value="+82">+82 (South Korea)</MenuItem>
                  <MenuItem value="+34">+34 (Spain)</MenuItem>
                  <MenuItem value="+46">+46 (Sweden)</MenuItem>
                  <MenuItem value="+41">+41 (Switzerland)</MenuItem>
                  <MenuItem value="+66">+66 (Thailand)</MenuItem>
                  <MenuItem value="+90">+90 (Turkey)</MenuItem>
                  <MenuItem value="+44">+44 (United Kingdom)</MenuItem>
                  <MenuItem value="+1">+1 (USA)</MenuItem>
                  <MenuItem value="+58">+58 (Venezuela)</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                fullWidth
                required
                error={!!phoneError}
                helperText={phoneError||"We ask for your phone number to enable MFA"}
                InputLabelProps={{
                  style: {
                    fontFamily: "Manrope",
                    fontWeight: 250,
                  },
                }}
                disabled={isOtpSent}
              />
            </Box> */}

            <div style={{ marginBottom: '50px' }}>
              <CustomButton
                label="Register member"
                filled={true}
                custFontSize={16}
                iconName={'arrow-right'}
                size="large"
                onClick={handleSubmit}
                disabled={
                  (emailError || emailConfirmError || passwordError || confirmPasswordError) || (orgId === '' || email === '' || password === '' || confirmPassword === '')
                    ? true : false
                }
              />
            </div>
            {/* {isOtpSent && (
              <>
                <TextField
                  label="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  onClick={handleOtpVerification}
                  fullWidth
                >
                  Verify OTP
                </Button>
              </>
            )} */}

            <div id="recaptcha-container"></div>
          </>
        )}
      </Box>
    </div>
  );
};

export default RegisterScreen;
