import React, { useState, useEffect } from "react";
import { white } from "../../lib/rebrandColors/colors";
import "../../App.css"; // Import your fonts
import DailyScheduleView from "../../components/dailyScheduleView";
import TaskList from "../../components/tasksList";
import OutletList from "../../components/outletList";
import { fetchOrgSchedules } from "../../functions/scheduleTable";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { fetchUserRole } from "../../functions/users";
import { useNavigate } from "react-router-dom";
import { fetchOutlets } from "../../functions/outlets";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import { fetchSavings } from "../../functions/savings";
import { fetchTasks } from "../../functions/tasks";
import OrganizationPicker from "../../components/orgPicker";
import CustomLogo from "../../components/logo";
import { fetchOrganizationInfoFromID } from "../../functions/organizations";
import { useOutletContext } from "react-router-dom";
import DailyUsageChart from "../../components/dailyUsageChart";
import SavingsDisplay from "../../components/savingsDisplay";
import BuildingsContainer from "../../components/buildingsContainer";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export default function Dashboard() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [user, waiting, error] = useAuthState(auth);
  // variables from layout
  const {
    orgID,
    orgName,
    isOverviewAccount,
    selectedOrgID,
    // child_orgs,
    timezone,
    allProdTypes,
    allOrgs,
    setTimezone,
    setRate,
    setSelectedOrgID,
    // setChild_Orgs
  } = useOutletContext();
  // console.log("ALL ORGS: ",allOrgs)
  const [roleLoading, setRoleLoading] = useState(true);
  const [prodTypes, setProdTypes] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [update, setUpdate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [wattHours, setWattHours] = useState([]);
  const [savings, setSavings] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [savingsLoading, setSavingsLoading] = useState(true);
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [averageUsage, setAverageUsage] = useState([]);
  const [averageLoading, setAverageLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Store the date selected by TimePicker
  const [orgData, setOrgData] = useState([]);
  const [child_orgs, setChild_Orgs] = useState([]);
  const [role, setRole] = useState("");

  // gets userRole
  useEffect(() => {
    if (waiting) {
      // maybe trigger a loading screen
      return;
    };
    const getRole = async () => {
      const userRole = await fetchUserRole(user.uid);
      setRoleLoading(false);
      setRole(userRole);
      if (userRole == "pending") {
        navigate("/pending-role");
      };
    };
    if (user) {
      getRole();
    } else {
      navigate("/login");
    };
  }, [user, waiting]);

  // get the following information from org id or selected org id
  // - child orgs, schedules, watt hours, tasks
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      //get the (selected) org document information from the firebase
      let orgData = [];
      let orgNameArray = [];
      if (selectedOrgID !== "") {
        orgData = await fetchOrganizationInfoFromID(selectedOrgID);
        setOrgData([orgData]);
      } else if (orgID !== "") {
        orgData = await fetchOrganizationInfoFromID(orgID);
        setOrgData([orgData]);
      };

      //if the (selected) org has child orgs make sure to update the child orgs in storage
      if (orgData.child_orgs) {
        setChild_Orgs(orgData.child_orgs);
        localStorage.setItem("child_orgs", orgData.child_orgs);
        if (orgData.child_orgs.length > 0) {
          const orgDataPromises = orgData.child_orgs.map((org) =>
            fetchOrganizationInfoFromID(org)
          );
          const orgDataArray = await Promise.all(orgDataPromises);
          setOrgData(orgDataArray);
        };
      } else {
        setChild_Orgs([]);
        localStorage.setItem("child_orgs", []);
      };

      //get schedules and wattHours
      if (orgData.child_orgs) {
        if (orgData.child_orgs.length > 0) {
          fetchOrgSchedules(
            orgData.child_orgs,
            setSchedules,
            setScheduleLoading
          );
          fetchWattHours(orgData.child_orgs, setWattHours, setWattsLoading);
          fetchSavings(orgData.child_orgs, setSavings, setSavingsLoading);
          fetchTasks(orgData.child_orgs, setTasks, setTasksLoading);
          fetchAverageUsage(
            orgData.child_orgs,
            setAverageUsage,
            setAverageLoading
          );
        };
      } else if (selectedOrgID !== "") {
        fetchOrgSchedules([selectedOrgID], setSchedules, setScheduleLoading);
        fetchWattHours([selectedOrgID], setWattHours, setWattsLoading);
        fetchSavings([selectedOrgID], setSavings, setSavingsLoading);
        fetchTasks([selectedOrgID], setTasks, setTasksLoading);
        fetchAverageUsage([selectedOrgID], setAverageUsage, setAverageLoading);
      } else {
        if (orgID !== "") {
          fetchOrgSchedules([orgID], setSchedules, setScheduleLoading);
          fetchWattHours([orgID], setWattHours, setWattsLoading);
          fetchSavings([orgID], setSavings, setSavingsLoading);
          fetchTasks([orgID], setTasks, setTasksLoading);
          fetchAverageUsage([orgID], setAverageUsage, setAverageLoading);
        };
      };
    };
    fetchDashboardInfo();
  }, [orgID, selectedOrgID, tasksLoading]);

  // refresh interval
  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(!update);
    }, 120000); // 120000ms = 120 seconds
    return () => {
      clearInterval(interval);
    };
  }, [update]);

  // gets outlets
  useEffect(() => {
    setOutletsLoading(true);

    if (selectedOrgID !== "" && child_orgs.length == 0) {
      fetchOutlets(
        [selectedOrgID],
        timezone,
        setOutlets,
        setOutletsLoading,
        setProdTypes
      );
    } else if (child_orgs.length > 0) {
      if (Array.isArray(child_orgs)) {
        fetchOutlets(
          child_orgs,
          timezone,
          setOutlets,
          setOutletsLoading,
          setProdTypes
        );
      } else {
        fetchOutlets(
          child_orgs.split(","),
          timezone,
          setOutlets,
          setOutletsLoading,
          setProdTypes
        );
      };
    } else {
      fetchOutlets(
        [orgID],
        timezone,
        setOutlets,
        setOutletsLoading,
        setProdTypes
      );
    };
  }, [schedules, update, modalEdit, selectedOrgID, orgID, child_orgs]);

  // resize function
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div style={{
        display: "flex",
        backgroundColor: "#1F1F1F",
        height: "auto",
        minHeight: "100vh",
        minWidth: "1300px",
        justifyContent: "center",
        marginTop: "86px",
      }}>
        <div style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <div
              className="headlineLarge"
              style={{
                width: "350px",
                height: "auto",
                display: "block",
                alignSelf: "start",
                marginTop: "10px",
                color: "#FFFFFF",
              }}
            >
              Dashboard
            </div>
            {(isOverviewAccount === "true" ||
              orgID === "LZqI3R6MInUuwtgtROPK") && (
                <OrganizationPicker
                  allOrgs={allOrgs}
                  orgID={orgID}
                  selectedOrgID={selectedOrgID}
                  setSelectedOrgID={setSelectedOrgID}
                  setTimezone={setTimezone}
                  setRate={setRate}
                  label={true}
                />
              )}
            {/* <div>
                            <CustomButton
                                filled={true}
                                label="Customize"
                                iconName={'dashboard-square'}
                                iconPosition="left"
                                disabled={true}
                            />
                        </div> */}
          </div>
          <div style={{
            marginTop: "30px", //windowDimensions.height < 415 ? '30px' : '8vh',
            display: "flex",
            flexDirection: "row",
            marginBottom: "30px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={{
                width: "560px",
                display: "flex",
                borderRadius: "24px",
                minHeight: "693px",
                backgroundColor: "#FAFAFA",
                maxHeight: "693px",
                overflowY: "hidden",
                marginRight: "16px",
                justifyContent: "center",
                marginBottom: "15px",
              }}>
                <div style={{ width: "95%" }}>
                  {/* schedule component */}
                  <DailyScheduleView
                    width={windowDimensions.width}
                    height={"615px"}
                    schedules={schedules}
                    day={selectedDate}
                    orgID={orgID}
                    selectedOrgID={selectedOrgID}
                    isOverviewAccount={isOverviewAccount}
                    timezone={timezone}
                    orgName={orgName}
                    child_orgs={child_orgs}
                    orgData={orgData}
                    loading={scheduleLoading}
                    outlets={outlets}
                    role={role}
                  />
                </div>
              </div>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                minHeight: "339px",
                backgroundColor: "#FAFAFA",
                maxHeight: "339px",
                overflowY: "hidden",
                marginBottom: "15px",
              }}>
                <TaskList
                  loading={tasksLoading}
                  height={"255px"}
                  tasks={tasks}
                  setTasksLoading={setTasksLoading}
                />
              </div>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                minHeight: "500px",
                backgroundColor: "#FAFAFA",
                maxHeight: "620px",
                overflowY: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}>
                <DailyUsageChart
                  wattHours={wattHours}
                  wattsLoading={wattsLoading}
                  averageUsage={averageUsage}
                  averageLoading={averageLoading}
                  savings={savings}
                  savingsLoading={savingsLoading}
                />
                <div style={{ marginTop: '50px', marginBottom: '20px', width: '100%' }}>
                  <SavingsDisplay
                    savings={savings}
                    savingsLoading={savingsLoading}
                  />
                </div>
              </div>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                minHeight: "339px",
                backgroundColor: "#FAFAFA",
                maxHeight: "339px",
                overflowY: "hidden",
                marginBottom: "15px",
              }}>
                <OutletList
                  outlets={outlets}
                  setOutlets={setOutlets}
                  height={275}
                  wattHours={wattHours}
                  savings={savings}
                  orgData={orgData}
                  loading={outletsLoading}
                  prodTypes={allProdTypes}
                  modalEdit={modalEdit}
                  setModalEdit={setModalEdit}
                  averageUsage={averageUsage}
                  role={role}
                />
              </div>
              {/*UNCOMMENT THE BELOW WHEN READY FOR BUILDINGS CONTAINER*/}
              {/* {(isOverviewAccount === "true" ||
                orgID === "LZqI3R6MInUuwtgtROPK") && (
                <div
                  style={{
                    width: "560px",
                    borderRadius: "24px",
                    minHeight: "339px",
                    backgroundColor: "#FAFAFA",
                    // maxHeight: "349px",
                    overflowY: "hidden",
                    // scrollbarWidth:"none"
                  }}
                >
                  <BuildingsContainer orgID={orgID} selectedOrgID={selectedOrgID} />
                </div>
              )} */}
            </div>
          </div>
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}>
            <CustomLogo name="horizontal-white" />
          </div>
        </div>
      </div>
    </>
  );
};
