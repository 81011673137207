import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { auth } from "../../firebase";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import db from "../../firebase";
import { registerNewUser } from "../../functions/users";
import { registerOrganization } from "../../functions/organizations";
import { fetchGlobalSavings } from "../../functions/savings"
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import TextField from "../../components/textField";
import CustomButton from "../../components/customButton";
import CustomLogo from "../../components/logo";
import { white } from "../../lib/rebrandColors/colors";
import { Alert } from "@mui/material";
import '../../App.css'; // Import your fonts
import hash from 'hash.js';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export default function OrganizationRegistration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [zipCode, setZipCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [organizationName, setOrganizationName] = useState("");
  const [utilityRate, setUtilityRate] = useState("0.16");
  const [rateError, setRateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailConfirmError, setEmailConfirmError] = useState(false);
  const [orgError, setOrgError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [registrationCode, setRegistrationCode] = useState('');
  const [registrationCodeError, setRegistrationCodeError] = useState(false);
  const [validateCode, setValidateCode] = useState(false);
  const secretHash = process.env.REACT_APP_REGISTRATION_KEY;

  const [savings, setSavings] = useState({
    rev_co2eSavings: 0,
    rev_costSavings: 0,
    rev_energySavings: 0,
  });

  useEffect(() => {
    const loadSavings = async () => {
      const fetchedSavings = await fetchGlobalSavings()
      setSavings(fetchedSavings)
    };

    loadSavings();
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  // resize function
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRateChange = (value) => {
    // const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setUtilityRate(value);
      setRateError(false);
    } else {
      setRateError(true);
    };
  };
  const handleZipcodeChange = (value) => {
    // const value = e.target.value;
    if (/^\d{5}$/.test(value)) {
      setZipCode(value);
      setZipcodeError(false);
    } else {
      setZipcodeError(true);
    };
  };
  const handleUsersCreation = async () => {
    const auth = getAuth();
    let users = [];

    // Determine the role based on the referring page
    const role = location.state?.from === "/login" ? "admin" : "admin";


    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await registerNewUser(user, null, role); // Pass the determined role ("pending" or "admin")
      users.push(user.uid);
    } catch (error) {
      console.error(`Error creating user with email ${email}:`, error);
      setAlert({
        show: true,
        message: `Error creating user with email ${email}: ${error.message}`,
        severity: "error",
      });
      return false;
    };

    return users;
  };

  const handleEmailChange = (value) => {
    // const value = e.target.value;

    // Basic email regex pattern:
    // - Starts with one or more characters (letters, numbers, dots, underscores, or hyphens)
    // - Must contain the @ symbol
    // - Domain name part with one or more characters
    // - Dot (.) followed by a valid top-level domain (2-6 characters)
    const isValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/.test(value);

    if (isValid) {
      setEmail(value);
      setEmailError(false);
    } else {
      setEmailError(true);
    };
  };

  const handlePasswordChange = (value) => {
    // const value = e.target.value;

    // Regex checks for:
    // - Minimum 8 characters
    // - At least one uppercase letter
    // - At least one lowercase letter
    // - At least one digit
    // - At least one special character
    const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value);

    if (isValid) {
      setPassword(value);
      setPasswordError(false);
    } else {
      setPasswordError(true);
    };
  };

  const handleConfirmPasswordChange = (value) => {
    // const value = e.target.value;
    const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value);
    const passwordValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(password);

    if (isValid && passwordValid) {
      setConfirmPassword(value);
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }
  };

  const handleConfirmEmail = async () => {
    try {
      const auth = getAuth();
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.length === 0) {
        setEmailConfirmError(false);
        return true; // Email is valid and not in use
      } else {
        setEmailConfirmError(true);
        setEmailError(true);
        return false; // Email is already in use
      };
    } catch (error) {
      console.error("Error confirming email:", error);
      setEmailConfirmError(true);
      return false;
    };
  };

  const handleConfirmOrgName = async () => {
    try {
      const q = query(collection(db, 'organizations'), where('name', '==', organizationName));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking org name', error)
      return false
    };
  };

  const handleOrganizationNameChange = (name) => {
    if (name.length > 0) {
      setOrganizationName(name);
      setOrgError(false);
    } else {
      setOrgError(true);
    };
  };

  const handleAddressChange = (name) => {
    setStreetAddress(name);
  };

  const handleSubmit = async () => {
    // e.preventDefault();

    // Validate before submission
    const emailIsValid = await handleConfirmEmail();
    if (!emailIsValid) {
      setProcessing(false); // Stop processing if email is invalid
      return; // Exit early if email validation fails
    };
    const orgNameAvailable = await handleConfirmOrgName();
    if (orgNameAvailable) {
      setProcessing(false);
      setOrgError(true);
      return;
    };

    setProcessing(true); // Start processing (show overlay)

    try {
      let users = await handleUsersCreation();
      if (users) {
        // Combine the address fields into one address string
        const fullAddress = `${streetAddress} ${zipCode}`;

        // Determine the organization status based on the referring page
        // should always be active now
        const orgStatus =
          location.state?.from === "/login" ? "active" : "active";

        const organization = {
          joinDate: "",
          name: organizationName,
          rate: parseFloat(utilityRate) || 0.16,
          uid: "",
          users: users,
          address: fullAddress,
          zipcode: zipCode,
          status: orgStatus,
        };

        const registeredID = await registerOrganization(organization);

        // Show success alert
        setAlert({
          show: true,
          message: "Organization registered successfully!",
          severity: "success",
        });
        setTimeout(() => {
          // location.state?.from === "/login"
          //     ? navigate("/login", {
          //         //state: { from: window.location.pathname },
          //     })
          //     :
          navigate("/");
        }, 1500);
      };
    } catch (error) {
      console.error("Error registering organization:", error);
      setAlert({
        show: true,
        message: `Error registering organization: ${error.message}`,
        severity: "error",
      });
    } finally {
      setProcessing(false); // End processing
    };
  };

  const handleRegistrationCodeChange = (code) => {
    if (code.length > 0) {
      setRegistrationCode(code);
      setRegistrationCodeError(false);
    } else {
      setRegistrationCodeError(true);
    };
  };

  const handleSubmitCode = () => {
    const inputHash = hash.sha256().update(registrationCode).digest('hex');
    if (inputHash === secretHash) {
      setValidateCode(true);
    } else {
      setValidateCode(false);
      setRegistrationCodeError(true);
    };
  };

  const text = "Plug in,\nstart saving.";

  const handleLogoClick = () => {
    window.location.href = 'https://reverttechnologies.com/';
  };
  
  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#1F1F1F',
        height: 'auto',
        minHeight: '100vh',
        minWidth: '1000px',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1%'
      }}>
        <div style={{
          display: "flex",
          width: '50%',
          minWidth: '550px',
          alignItems: "center",
          flexDirection: "column",
        }}>
          <div style={{
            alignSelf: 'center'
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: 'start',
              marginLeft: '5%',
            }}>
              <div
                style={{
                  width: "160px",
                  height: "auto",
                  display: "block",
                  alignSelf: 'start',
                  marginTop: '10%',
                  cursor: 'pointer',
                }}
                onClick={handleLogoClick}
              >
                <CustomLogo
                  name="horizontal-negative"
                />
              </div>
              {/*Enter Registration Code*/}
              {validateCode !== true &&
                <div style={{ height: '90vh', }}>
                  <div
                    className="displayLarge"
                    style={{
                      color: white,
                      fontWeight: 700,
                      fontSize: "48px",
                      textAlign: "left",
                      marginTop: '50px',
                      alignSelf: 'start'
                    }}
                  >
                    Register
                  </div>
                  <div
                    className="bodyLarge"
                    style={{
                      color: '#B7B7B7',
                      fontSize: "16px",
                      width: '450px',
                      textAlign: "left",
                      alignSelf: 'start',
                      marginBottom: '40px',
                    }}
                  >
                    Input your registration code to register your organization.
                  </div>
                  <TextField
                    label="Registration Code"
                    placeholder="Enter registration code"
                    size="medium"
                    onChange={handleRegistrationCodeChange}
                    visibility={true}
                    descriptionText={registrationCodeError ? 'Please enter a valid code' : ''}
                    textError={registrationCodeError ? true : false}
                  />
                  <div style={{marginTop:'15px',}}>
                  <CustomButton
                    label="Submit Code"
                    filled={true}
                    iconName={'arrow-right'}
                    size="large"
                    onClick={handleSubmitCode}
                    disabled={(registrationCodeError || registrationCode?.length === 0) ? true : false}
                  />
                  </div>
                </div>
              }
              {/*Registration Form*/}
              {validateCode === true &&
                <div style={{ height: '90vh', }}>
                  <div
                    className="displayLarge"
                    style={{
                      color: white,
                      fontWeight: 700,
                      fontSize: "48px",
                      textAlign: "left",
                      marginTop: '50px',
                      alignSelf: 'start',
                    }}
                  >
                    Register
                  </div>
                  <div
                    className="bodyLarge"
                    style={{
                      color: '#B7B7B7',
                      fontSize: "16px",
                      textAlign: "left",
                      alignSelf: 'start',
                      marginBottom: '20px',
                    }}
                  >
                    Register your organization and sign up as the organization's account administrator.
                  </div>
                  <div style={{
                    marginTop: '10px',
                    alignSelf: 'start'
                  }}>
                    <TextField
                      label="Organization Name*"
                      placeholder="Revert Technologies"
                      size="medium"
                      onChange={handleOrganizationNameChange}
                      descriptionText={orgError ? 'Please enter a valid Organization Name' : ''}
                      textError={orgError ? true : false}
                    />
                    <TextField
                      label="Email*"
                      placeholder="help@reverttechnologies.com"
                      size="medium"
                      onChange={handleEmailChange}
                      descriptionText={
                        emailError
                          ? emailConfirmError ? 'Email already in use'
                            : 'Please enter a valid email'
                          : ''
                      }
                      textError={emailError || emailConfirmError ? true : false}
                    />
                    <TextField
                      label="Service Address*"
                      placeholder="123 Energy Savings Ave, Brunswick, Maine"
                      size="medium"
                      onChange={handleAddressChange}
                    />
                    <div style={{
                      display: 'flex',
                    }}>
                      <TextField
                        label="Service Zip Code*"
                        placeholder="04011"
                        size="medium"
                        onChange={handleZipcodeChange}
                        descriptionText={zipcodeError ? 'Please enter a valid zip code' : ''}
                        textError={zipcodeError ? true : false}
                      />
                      {/* <div style={{ marginLeft: '50px' }}>
                      <TextField
                        label="Utility Rate"
                        placeholder="0.16"
                        descriptionText={rateError ? 'Please enter a valid number' : '$/kWh'}
                        size="extraSmall"
                        onChange={handleRateChange}
                        textError={rateError ? true : false}
                      />
                    </div> */}
                    </div>
                    <TextField
                      label="Password*"
                      placeholder="Password"
                      visibility={true}
                      size="medium"
                      onChange={handlePasswordChange}
                      descriptionText={passwordError ? 'Passwords require upper and lower case letters, a number, and a special character' : ''}
                      textError={passwordError ? true : false}
                    />
                    <div style={{
                      marginBottom: '30px'
                    }}>
                      <TextField
                        label="Confirm Password*"
                        placeholder="Password"
                        visibility={true}
                        size="medium"
                        onChange={handleConfirmPasswordChange}
                        descriptionText={confirmPasswordError ? 'Passwords must match' : ''}
                        textError={confirmPasswordError ? true : false}
                      />
                    </div>
                    <div style={{
                    }}>
                      {alert.show && (
                        <Alert severity={alert.severity} sx={{ mb: 3 }}>
                          {alert.message}
                        </Alert>
                      )}
                      <CustomButton
                        label="Register Organization"
                        filled={true}
                        iconName={'arrow-right'}
                        size="large"
                        onClick={handleSubmit}
                        disabled={
                          (orgError || emailError || emailConfirmError || zipcodeError || rateError || passwordError || confirmPasswordError) || (organizationName === '' || email === '' || zipCode === '' || password === '' || confirmPassword === '')
                            ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              }
              {/* Terms Of Service */}
              <div
                style={{
                  // width: '70%',
                  marginBottom: "2.5%",
                  marginTop: "3.5%",
                  fontSize: '11px',
                  color: '#999999',
                  textAlign: 'center',
                  marginLeft: '2%',
                  alignSelf: 'start',
                }}
                className="labelLarge"
              >
                By registering your organization you agree to Revert's{" "}
                <a
                  href="https://reverttechnologies.com/policies/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#999999' }}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://reverttechnologies.com/policies/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#999999' }}
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
        </div>
        {/* Impact Sumary */}
        <div
          style={{
            alignSelf: 'center',
            display: "flex",
            height: '95vh',
            minHeight: '850px',
            width: '47.5%',
            minWidth: '400px',
            flexDirection: "column",
            backgroundColor: 'white',
            borderRadius: '20px',
          }}
        >
          <div style={{
            display: "flex",
            flexDirection: 'column',
            marginTop: '75px',
            marginLeft: '45px'
          }}>
            <div className="displayLarge"
              style={{
                whiteSpace: 'pre-line',
                fontWeight: 700,
                fontSize: '80px',
                lineHeight: '104px',
                color: '#2D2D2D',
              }}
            >
              {text}
            </div>
            <div className='titleLarge'
              style={{
                color: '#666666',
                marginTop: '150px'
              }}
            >
              Across all customers
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '95%',
              justifyContent: 'space-between',
              marginTop: '22px',
            }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="titleLarge"
                  style={{
                    color: '#666666',
                    fontSize: '20px',
                  }}
                >
                  Energy Savings
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                >
                  <div className="displayMedium"
                    style={{
                      fontWeight: '700',
                      color: '#999999',
                    }}
                  >
                    {(savings.rev_energySavings / 1000000).toFixed(1)}
                  </div>
                  <div className="titleLarge" style={{
                    color: '#999999',
                    lineHeight: '70px',
                    marginLeft: '5px',
                  }}
                  >
                    mWh
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="titleLarge"
                  style={{
                    color: '#666666',
                    fontSize: '20px',
                  }}
                >
                  Bill Savings
                </div>
                <div className="displayMedium"
                  style={{
                    fontWeight: '700',
                    color: '#999999',
                  }}
                >
                  ${(savings.rev_costSavings).toFixed(2)}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="titleLarge"
                  style={{
                    color: '#666666',
                    fontSize: '20px',
                  }}
                >
                  CO2e Savings
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                >
                  <div className="displayMedium"
                    style={{
                      fontWeight: '700',
                      color: '#999999',
                    }}
                  >
                    {(savings.rev_co2eSavings / 2000).toFixed(1)}
                  </div>
                  <div className="titleLarge" style={{
                    color: '#999999',
                    lineHeight: '70px',
                    marginLeft: '5px',
                  }}
                  >
                    tons
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};