import React, { useEffect, useState } from "react";
import CustomIcon from "../icon";
import { grey500, primary500, white, primary600, primary580, primary550 } from "../../lib/rebrandColors/colors";
import '../../App.css'; // Import your fonts

export default function TextButton({
    label = 'label',
    disabled,
    underlined,
    icon,
    iconPosition = 'right',
    onPress,
    fill, //most likely mainly used in tab bar
    fontSize,
}) {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const lineColor = fill ? fill : underlined ?
        grey500 : 'transparent';

    const opacity = disabled ?
        .3
        : 1;

    const iconHeight = 18;

    const buttonLabelStyle = {
        color: fill ? fill : grey500,
        opacity: opacity,
        lineHeight: '140%', /* 19.6px */
        borderBottom: underlined ? `2px solid ${fill ? fill : grey500}` : 'none',
        marginLeft: iconPosition === 'left' ? '10px' : 0,
        marginRight: iconPosition === 'right' ? '10px' : 0,
        fontSize: fontSize ? fontSize : '',
    };

    const handleClick = () => {
        if (!disabled) {
            onPress();
        };
    };

    return (
        <div
            onMouseEnter={() => !disabled && !isPressed && setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseDown={() => !disabled && setIsPressed(true)}
            onMouseUp={() => setIsPressed(false) && setIsHovered(false)}
            onFocus={() => !disabled && setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onClick={handleClick}
            style={{ cursor: disabled ? "not-allowed" : "pointer",}}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                {icon ? iconPosition === 'left' &&
                    <CustomIcon
                        name={icon}
                        size={iconHeight}
                        color={lineColor}
                        opacity={opacity}
                        strokeWidth='2.5'
                    />
                    : null
                }
                {label ?
                    <div className="buttonLargeBold"
                        style={buttonLabelStyle}
                    >
                        {label}
                    </div>
                    : null
                }
                {icon ? iconPosition === 'right' &&
                    <CustomIcon
                        name={icon}
                        size={iconHeight}
                        color={lineColor}
                        opacity={opacity}
                        strokeWidth='2.5'
                    />
                    : null
                }
            </div>
        </div>
    );
};